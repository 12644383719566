import { React, useState,useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import SignUp from "../Components/SignUp";
import Login from "../Components/Login";
import GameCard from "../Components/GameCard";
import GameCardSmall from "../Components/GameCardSmall"
import GameCarousel from "../Components/GameCarousel";
import StyledButton from "../GlobalStyles/StyledButton";
import StyledTextField from "../GlobalStyles/StyledTextField";
import {AuthContext} from "../Contexts/AuthContext"
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import  { notifyError, notifySuccess } from "../Helpers/Toasts"
import CircularProgress from '../GlobalStyles/Logo';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import axios from "axios";
const UserInfo = () => {
    let game;
    const [userExists,setUserExists] = useState(false)
    const [selectedGame,setSelectedGame] = useState(0)
    const [isLoading,setIsLoading] = useState(true)
    const {userName} = useParams();
    const {token} = useContext(AuthContext)
    const navigate = useNavigate()
    const { handleLogout } = useContext(AuthContext);
    const buttonSx={
      marginY:'8px',
      fontSize: { xs:'0.7rem',sm: '0.9rem', md: '0.8rem' ,xl:'1.0rem'},
      padding:{xs:'5px 10px 5px 10px',sm:'5px 25px 5px 25px'},
      border:"2px solid rgba(225, 225, 225, 0.5)",
      backgroundColor:"transparent",
      '&:hover': { color: 'rgba(255, 255, 255, 1)',backgroundColor:"transparent",border:"2px solid rgba(255, 255, 255, 1)", }
    }
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080";
    const logout = ()=>{
      // e.preventDefault()
      handleLogout();
      navigate('/')
    }
    const {
      data: user,
      userLoading,
      error,
      refetch
    } = useQuery("userInfo", async () => {
      const response = await axios.get(`${baseUrl}/api/users/get-user-by-name/${userName}`);
      if (response && response.data && response.data.user) {
        console.log(response.data.user);
        setUserExists(true)
        return response.data.user;
      }
    },{
      retry:false,
      staleTime:Infinity,
      onSuccess:()=>{
        setIsLoading(false)
      },
      onError: (error) => {
        notifyError(error.response.data.msg)
        navigate('/')
        console.log(error.response.data.msg)
      },
      
    });
    useEffect(() => {  
      refetch();
    }, [userName]);
    const {
        data:commentCount,
    }=useQuery("commentCount",async()=>{
        const response = await axios.get(`${baseUrl}/api/users/get-comment-count/${userName}`)
        if (response && response.data && response.data.commentCount) {
            console.log(response.data.commentCount);
            return response.data.commentCount;
          }
    },{staleTime:Infinity})
    const handleRerank =()=>{
      console.log("boo")
      console.log(selectedGame)
      let slug = user?.gamesRated[selectedGame]?.slugName
     
      navigate(`/ranking/${slug}`)

    }
    const typographySx={
        display:"flex",
        flexDirection:'column',
        alignSelf:'flex-start',
        alignItems:'center',
        fontFamily: "'Inter', sans-serif",
        fontWeight: "300",
        textAlign: 'center',
        width:'100%',
        fontSize: {
            xs: "1.2rem",
            sm: "1.3rem",
            md: "1.1rem",
            xl: "1.2rem",
        },
        lineHeight: '30px',
        // height:'105px'
        // border: '0.8px solid rgba(255, 255, 255, 0.29)',
    }
    const boxSx ={
        display:'flex',flexGrow:1,alignItems:'center',justifyContent:'center',width:{md:'20%'},borderRight:{md:'0.8px solid rgba(255, 255, 255, 0.29)'}
    }
    if (userLoading || isLoading) {
        // Handle loading state, e.g. show a loading spinner
        return (
          <>
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100vh'}}>
          <CircularProgress/>
        </Box>
          </>
        );
      }
    
      if (error) {
        // Handle error state, e.g. show an error message
        console.log(error)
        
        return <ToastContainer/>;
      }
      if(user){
          return (
              <>
                 <Box sx={{display:'flex',flexDirection:'column',mx:'auto',width:{xs:'100vw',md:'90vw'},maxWidth:'1440px'}}>
                  <Typography
                      mx={3}
                      mt={3}
                      mb={5}
                      variant="h3"
                      component="div"
                      sx={{
                      display: "flex",
                      justifyContent: 'center',
                      alignItems: "center",
                      flexGrow: 1,
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: "800",
                      padding: "10px",
                      textAlign: "center",
                      fontSize: { xs: "2.0rem", sm: "2.4rem", md: "2.5rem" },
                      }}
                  >
                      {user?.userName}'s Profile
                  </Typography>
                  <Box 
                      mx={3}
                      my={1}
                      sx={{
                        display:'flex',justifyContent: 'center',alignItems:'center',
                      background: 'background: rgba(255, 255, 255, 0.01)',
                      border: "0.8px solid rgba(255, 255, 255, 0.29)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "20px",
                      }}>
                          <Box  sx={{mx:'auto',width:'100%',justifyContent:'center',alignItems:'center',}}>
                            {user?.gamesRated.length!=0?<>
                          <Box   sx={{ borderBottom: "0.8px solid rgba(255, 255, 255, 0.29)",overflow: "hidden",width: "100%",mx: "auto",background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%)",borderTopLeftRadius:'20px',borderTopRightRadius:'20px'}}>
                              <Typography variant="h3" component="div" sx={{display:'flex',justifyContent: 'center', flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.6rem',sm: '1.9rem', md: '2.0rem' }, }}>
                              {user?.userName }'s Wall Of Fame
                              </Typography>
                          </Box>
                              <GameCarousel ratedList={user?.gamesRated} setSelectedGame={setSelectedGame}/>
                              <Box mb={1} sx={{mx:'auto', display:'flex',justifyContent: 'center',alignItems:'center',}}>

                              <StyledButton text="Rank" sx={buttonSx} onClick={()=>handleRerank()}/>
                              </Box>
                            </>:<>
                           

                            <Typography m={2} variant="h3" component="div" sx={{display:'flex',justifyContent: 'center',alignItems:'center', flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.6rem',sm: '1.9rem', md: '2.0rem' }, }}>
                              {user?.userName } hasn't ranked any games yet
                              </Typography>
                            
                            </>}
                          </Box>
                          <Box my={2}  sx={{display:'flex',justifyContent:'center'}}>
                          
                          </Box>
                  </Box>
                  <Box  sx={{display:'flex',justifyContent: 'center',alignItems: "stretch",flexDirection:{xs:'column',md:'row'},width:'90vw',mx:'auto',maxWidth:'1400px',background: 'rgba(255, 255, 255, 0.01)',
                          border: '0.8px solid rgba(255, 255, 255, 0.29)',
                          backdropFilter: 'blur(10px)',
                          borderRadius: '20px',height:'100%'}}>
                      <Box sx={boxSx}>
                          <Typography
                          
                          p={2}
                          component="div"
                          sx={typographySx}
                          >
                          <b>{user?.userName || 'Flader\n'}</b> 
                          {user?.timesRanked || "0"} Rankings
                          </Typography>
                      </Box>
      
                      <Box sx={{...boxSx}}>
                          <Typography              
                          p={2}
                          component="div"
                          sx={typographySx}
                          >
                          <b>Favorite Game</b> 
                          {user?.gamesRated?user.gamesRated[0]?.gameName:"-"}
                          </Typography>
                      </Box>
      
                      <Box sx={boxSx}>
                          <Typography
                          p={2}
                          component="div"
                          sx={typographySx}
                          >
                          <b>Joined</b> 
                          {user?.createdAt? 
                     moment(user.createdAt).format("LL")
                  :"-"}
                          </Typography>
                      </Box>
      
                      <Box sx={boxSx}>
                          <Typography
                          p={2}
                          component="div"
                          sx={typographySx}
                          >
                          <b>Activity</b> 
                          {commentCount?commentCount:"0"} comments
                          </Typography>
                      </Box>
      
                      <Box sx={{...boxSx,borderRight:'0px'}}>
                          <Typography
                          p={2}
                          component="div"
                          sx={typographySx}
                          >
                          <b>Last Online</b> 
                          {user?.updatedAt? 
                     moment(user.updatedAt).format("LL")
                  :"-"}
                          </Typography>
                      </Box>
                      
                  </Box>
                  {
                    token && 
                  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <StyledButton text="Log out" sx={{...buttonSx,padding:'5px 35px 5px 35px'}} onClick={()=>logout()}/>
                  </Box>
                  }
                  
            </Box>
            <ToastContainer/>
              </>
          );
      }
};

export default UserInfo;