import {React,useRef,useState} from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from '@mui/material';
import { Box, Container, Typography } from "@mui/material";
import "@splidejs/react-splide/css";
import GameCard from "./GameCard";


const GameCarousel = ({ratedList,setSelectedGame}) => {
  const splideRef = useRef(null);
  const titleSx = {
    fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1.2rem", },
  };
  const cardSx={
    mx:'auto',
    width:{md:'224px'},
    height:{md:'305px'}
  }
  const [activeSlide, setActiveSlide] = useState(0);
  
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const handleMove = (splide, newIndex) => {
    setActiveSlide(newIndex);
    setSelectedGame(newIndex)
  };

  
  return (
    <>
      <Splide
      ref={splideRef}
        options={{
          type: "loop",
          perPage: isXsScreen ? 1 : 3,
          rewind: true,
          focus  : 'center',
          speed: 400,
          arrows: true,
          perMove: 1,
          pagination: true,
          
        }}
        onMove={handleMove}
      >
        
          
        
        {ratedList.map((game,index)=>
          <>
            <SplideSlide>
              <Box sx={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}} mx={2}>
          <Typography
            mt={1}
            component="div"
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: "300",
              textAlign: { xs: "center" },
              fontSize: {
                xs: "0.9rem",
                sm: "1.0rem",
                md: "1.1rem",
                xl: "1.2rem",
              },
            }}
          >
            <b>#{index+1}</b>
          </Typography>
          

          <GameCard cardSx={cardSx} titleSx={titleSx} game={game}/>
          
              </Box>
        </SplideSlide>
          </>
        )}
        

        {/* <SplideSlide>
          <Typography
           mt={1}
            component="div"
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: "300",
              textAlign: { xs: "center" },
              fontSize: {
                xs: "0.9rem",
                sm: "1.0rem",
                md: "1.1rem",
                xl: "1.2rem",
              },
            }}
          >
            <b>#1</b>
          </Typography>
          <GameCard
          cardSx={cardSx}
            titleSx={titleSx}
          />
        </SplideSlide> */}
       
      </Splide>
      <style jsx={true}>{`

  .splide__slide {
    opacity: 0.37;
    transform: scale(1.0);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  }
  @media (max-width:768px){
    .splide__slide {
      opacity: 1;
      
    }
  }
  .splide__slide.is-active {
    opacity: 1;
    // transform: scale(1.05);
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  }


.splide__arrow {
  background:rgba(255, 255, 255, 0.29)
  
    align-items: center;
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2em;
    justify-content: center;
    opacity: .7;
    margin-left: 10px;
    position: absolute;
    top: 50%;
     transform: translateY(0%); 
    width: 2em;
    z-index: 1;
}

.splide__pagination{
  display: none;
}
.splide__arrow--prev {
   left: 0em;
}
// .splide__list{
//  transform: translateX(0px) !important;
//  }

`}</style>
    </>
  );
};



export default GameCarousel;
