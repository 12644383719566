import { Box,Container,Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const CommentBox = ({comment}) => {
    const navigate = useNavigate()
    let testmsg = "New MW2 is cold. Would recommend  🔥🔥";
    // let comment= {
    //     "_id": "644bba1e5cb97e169b84f81c",
    //     "userId": {
    //         "_id": "63fbe6c7d7543bfac678bc73",
    //         "userName": "Flader"
    //     },
    //     "winId": "63f2abc4eb0ec7c62c4806df",
    //     "lossId": "63f2abc4eb0ec7c62c4809fb",
    //     "text": "I love ssbu",
    //     "__v": 0
    // }
    
    return (
        <>
            <Box  py={1.5} m={0.5} mx={1} sx={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}} >
                <Typography  component="div" sx={{ fontFamily: "'Inter', sans-serif"
                    ,fontWeight:'300',textAlign:"start",
                    fontSize: { xs:'0.9rem',sm: '1.0rem', md: '1.0rem',xl:'1.2rem' }, '&:hover': { textDecoration: 'underline' },cursor:'pointer'}} onClick={()=>navigate(`/user/${comment?.userId?.userName}`)}>
                        @{comment?.userId?.userName}
                </Typography>
                <Typography  component="div" sx={{ flexGrow: 0,fontFamily: "'Inter', sans-serif"
                    ,fontWeight:'300',textAlign:"start",
                    fontSize: { xs:'1.0rem',sm: '1.1rem', md: '1.1rem' }, opacity:'0.7',
                    wordBreak: 'break-word',}}>
                        {comment?.text
                        // .replace(/((?:\S+\s*){7,8})(.*)/, '$1\n$2')
                        }
                        {/* {testmsg} */}
                </Typography>
            </Box>
        </>
    );
};

export default CommentBox;