import {React,useState,useEffect,useContext} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container'

import SignUp from '../Components/SignUp';
import Login  from '../Components/Login';
import StyledButton from '../GlobalStyles/StyledButton';

import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import axios from 'axios';
// import { UserContext } from '../Contexts/UserContext';
import { AuthContext } from '../Contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
const Lander = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userName } = useContext(AuthContext);
  
    const [lander, setLander] = useState('lander');
  
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const landerQueryParam = searchParams.get('lander');
      if (landerQueryParam === 'login') {
        setLander('login');
      } 
      else if(landerQueryParam==='signup'){
        setLander('signup');
      }
      else {
        setLander('lander');
      }
    }, [location.search]);

    return (
        <>
            <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                <Box sx={{width:{xs:'100vw',md:'60vw'}, display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',justifySelf:'center',marginTop:{xs:'60px',md:'40px'}}}>
                    <Typography variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'800',padding:'10px',textAlign:"center",fontSize: { xs:'2.8rem',sm: '2.9rem', md: '3.0rem' }, }}>
                        The global game-ranking website!
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'400',padding:'10px',textAlign:"center",color: 'rgba(255, 255, 255, 0.58)' }}>
                        PlayChart is the best place to express your love for <br/> the most popular video games!
                    </Typography>
                </Box>
                {lander===("lander") && (<>
                <Box m={3} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',filter: 'drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.33))'}}>
                    <img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1685181353/heroBanner_xuuxmc.png" style={{width:'100%', maxWidth:'874px',borderRadius:'20px'}}/>

                <Box m={4} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',zIndex:1 }}>
                    <StyledButton
                        text = "Get Started"
                        sx={{backgroundColor:"#3656FF",}}
                        
                        onClick={()=>{
                          if(userName){
                            navigate('/ranking')
                          }
                          else{
                            setLander("signup");
                          }      
                           }}/>
                </Box>
                </Box>
                </>)}
                {
                    (lander==="signup") && (
                        <SignUp setLander={(value) => setLander(value)} />
                    )               
                }
                {
                    (lander==="login") && (
                        <Login setLander={(value) => setLander(value)} />
                    )               
                }

                    <Box sx={{position:'fixed',bottom:0,width:'50vw',height:'250px',marginTop:'-175px', borderRadius: '50%',background: 'rgba(54, 86, 255, 0.53)', filter: "blur(200px)",zIndex:-1,marginBottom:'-125px'}}>
                        
                    </Box>
                    <ToastContainer/>
            </Box>

        </>
    );
};

export default Lander;