import {React,useState,useEffect,useContext,useRef} from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import axios from 'axios';
import { AuthContext } from '../Contexts/AuthContext';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container'

import SignUp from '../Components/SignUp';
import Login  from '../Components/Login';
import RatedList from "../Components/RatedList"
import StyledButton from '../GlobalStyles/StyledButton';
import StyledTextField from '../GlobalStyles/StyledTextField';
import Rank from '../Components/Rank';
import RankedInfoCard from "../Components/RankedInfoCard"
import CommentContainer from '../Components/CommentContainer';
import { Divider } from '@mui/material';
import { ToastContainer } from 'react-toastify';

const GameRanking = () => {
    const [cookies, setCookie] = useCookies(['token']);
    const [rankWidth, setRankWidth] = useState(0);
  const rankRef = useRef(null);

  useEffect(() => {
    console.log('width changing')
    function handleResize() {
      if (rankRef.current) {
        setRankWidth(rankRef.current.offsetWidth);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [rankRef.current]);
    const navigate= useNavigate()
    const baseUrl= process.env.REACT_APP_BASE_URL || 'http://localhost:8080'
    const { userId,setUserId } = useContext(AuthContext); 
    const [prevGame,setPrevGame]= useState()
    const[rankedData,setRankedData] = useState()
    const [leftGame,setLeftGame] = useState()
    const [rightGame,setRightGame] = useState()
    return (
        <>
        
            <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',overflowX:'hidden',zIndex:2}}>
                {leftGame && rightGame && 
                <Box sx={{display:'flex',position:'absolute',width:'100%',height:'100%',opacity:0.15,flexDirection:{md:'row',xs:'column'},zIndex:-1}}>
                    <Box  sx={{display: 'flex',justifyContent:'center',width:{md:'50%',xs:'100%'},height:{xs:'50%',md:'100vh'}}} >
                    <img
                        src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${
                        leftGame?.coverUrl 
                        }.png`}
                        style={{ width: "100%", height: "100%",
                        filter: 'blur(15px)',
                        
                        // border: '2px solid white', 
                    }}  />
                    </Box>
                    <Box sx={{ display:{xs:'none',md:'flex'},width:'5px',background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0) 95%)" }}>
                        <Divider orientation="vertical" flexItem />
                    </Box>
                    <Box  sx={{display: 'flex',justifyContent:'center',cursor:'pointer',width:{md:'50%',xs:'100%'},height:{xs:'50%',md:'100%'}}}>
                    <img
                        src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${
                        rightGame?.coverUrl 
                        }.png`}
                        style={{ width: "100%", height: "100%",
                        filter: 'blur(15px)',
                        
                        // border: '2px solid white', 
                    }}  />
                    </Box>
                </Box>
                }
                <Box sx={{width:{xs:'100vw',md:'60vw'}, display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',justifySelf:'center',marginTop:{xs:'10px',md:'20px'}}}>
                    <Typography variant="h3" component="span" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'800',padding:'10px',textAlign:"center",fontSize: { xs:'2.0rem',sm: '2.4rem', md: '2.5rem' }, }}>
                        Rank your favorite games!
                    </Typography>
                </Box>

                {/* Content Wrapper */}
                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',flexGrow:1,maxWidth:{md:'1240px',xl:'1540px'},mx:'auto'}}>
                        <Box ref={rankRef} sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            {/* Top rankings */}
                            <Box sx={{display:{xs:'none',sm:'none',md:'flex'},maxWidth:{md:'232px'},minWidth:{xl:'200px'}}}>
                            <RatedList setRankedData={setRankedData} prevGame={prevGame}/>
                            </Box>
                            {/* Ranked Component */}
                            <Box sx={{width:{xs:'100vw',md:'60vw',xl:'40vw'},minWidth:{md:'500px'}}}>
                            <Rank setPrevGame={setPrevGame} setLeftGame={setLeftGame} setRightGame={setRightGame}/>
                            </Box>         
                            {/* Info card */}
                            <Box sx={{display:{xs:'none',md:'flex',}}}>
                            <RankedInfoCard prevGame={prevGame} ratedList={rankedData}/>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:{xs:'10px',md:'40px'}, flexGrow: 1,width:`${rankRef?.current?.offsetWidth}px`,minHeight:'200px'}}>
                            <CommentContainer leftGame={leftGame} rightGame={rightGame}/>
                        </Box>
                    </Box>
                    <ToastContainer/>
            </Box>

        </>
    );
};

export default GameRanking;