import { React, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "@mui/material";
import { Box, Container, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import ReactPlayer from 'react-player'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
const MediaCarousel = ({media}) => {
  const{screenshots,videos} = media
  const splideRef = useRef(null);
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const splideOptions = {
    perPage: isXsScreen ? 1 : 4,
    speed: 400,
    arrows: isXsScreen ? false: true,
    perMove: 1,
    pagination: true,
  };
  
  return (
    <>
    
      <Splide options={splideOptions} ref={splideRef}>
      {videos?.map((video)=>
        <SplideSlide>
            <Box m={isXsScreen?0.5:2}>
            <Card sx={{ width:'100%', borderRadius: "8px" }}>
                <CardActionArea>
                <CardMedia sx={{width:'100%'}}>
                <ReactPlayer url={`https://www.youtube.com/embed/${video.videoUrl}`} width={"100%"} height={"190px"} volume={0.4} controls={true} style={{userSelect:'none'}} playIcon={<PlayCircleFilledIcon fontSize="large"/>} light={true}/>
                
                 </CardMedia>

                <CardContent>
                    <Typography sx={{ fontFamily: "'Inter', sans-serif",fontWeight:'600',textAlign:"start",fontSize: { xs:'0.9rem',sm: '1.2rem', md: '1.0rem' },opacity:'0.37'}}>
                    {video?.name || "trailer"}
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card>
            </Box>
        </SplideSlide>
        )}
        {screenshots?.map((screenshot)=>
        <SplideSlide>
            <Box m={isXsScreen?0.5:2}>
            <Card sx={{ width:'100%', borderRadius: "8px" }}>
                <CardActionArea>
                <CardMedia onClick={()=>window.open(`https://images.igdb.com/igdb/image/upload/t_original/${screenshot.imageId}.jpg`, 'rel=noopener noreferrer')}>
                        <img src={`https://images.igdb.com/igdb/image/upload/t_original/${screenshot.imageId}.jpg`} style={{width:"100%",height:'246px'}}/>  
                 </CardMedia>
                {/* <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    Trailer
                    </Typography>
                </CardContent> */}
                </CardActionArea>
            </Card>
            </Box>
        </SplideSlide>
        )}

      </Splide>
    
    </>
  );
};

export default MediaCarousel;
