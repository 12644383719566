import React from "react";
import Button from "@mui/material/Button";
const StyledButton = ({ text, backgroundColor, fontWeight, sx, ...rest }) => {
  return (
    <Button
      {...rest}
      variant="contained"
      
      sx={{
        ...sx,
        fontWeight: fontWeight ? fontWeight : "300",
        color: "rgba(255, 255, 255, 0.8)",
        // backgroundColor: backgroundColor ? backgroundColor : "#3656FF",
        padding: sx?.padding ? sx.padding : "5px 25px 5px 25px",
        border: sx?.border?sx.border:" ",
        borderRadius: "12px",
        textTransform: "none",
      }}
    >
      {text}
    </Button>
  );
};

export default StyledButton;
// '#3656FF'
