import { React, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "@mui/material";
import { Box, Container, Typography } from "@mui/material";
import { TypographyStyles } from "../GlobalStyles/TypographyStyles";
import GameCardSmall from "./GameCardSmall";
import GameCard from "./GameCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import { CardActionArea } from "@mui/material";
// import ReactPlayer from 'react-player'
// import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
const ChartsCarousel = ({ title, games }) => {
  const splideRef = useRef(null);
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const splideOptions = {
    perPage: isXsScreen ? 2 : 4,
    speed: 400,
    arrows: isXsScreen ? false : true,
    pagination: true,
    gap    : '2rem',
  breakpoints: {
    1240: {
      perPage: 3,
      gap    : '.7rem',
      
    },
    1056: {
      perPage: 2,
      gap    : '.7rem',
      
    },
    
  },
  };

  const displayedGames = isXsScreen ? games.slice(0, 8) : games;

  return (
    <>
      <Box my={3} sx={{ width: "100%" }}>
        <Typography mb={2} sx={{ ...TypographyStyles.heading4, fontWeight: "600",textAlign:{xs:'center',md:'start'},width:'100%' }}>
          {title}
            {title==="Highest Rated" &&
              <Typography component='span' mx={1} sx={{ ...TypographyStyles.body2,color:'grey', fontWeight: "600" }}>
                (Last 90 days)
              </Typography>
            
            }
        </Typography>

        <Splide options={splideOptions} ref={splideRef}>
          {displayedGames.map((game,idx) => (
            <SplideSlide key={game.id}>
              <Box m={isXsScreen ? 0.5 : 2} mt={0}>
                {
                  isXsScreen?
                  <>
                    <GameCardSmall
                      charts={true}
                      game={game}
                      imgSx={{ width: "224px", height: "262px" }}
                    />
                  </>
                  :
                  <>
                    
                    <GameCard
                      charts={true}
                      showWinPercent={title==="Most Battles Won"}
                      index={idx}
                      game={game}
                      imgSx={{ width: "224px", height: "262px" }}
                    />
                  </>
                }
              </Box>
            </SplideSlide>
          ))}
        </Splide>
      </Box>
    </>
  );
};

export default ChartsCarousel;