import {React,useState,useContext} from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container'

import { useQuery } from "react-query";
import StyledButton from '../GlobalStyles/StyledButton';
import StyledTextField from '../GlobalStyles/StyledTextField';
import { AuthContext } from '../Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
const RatedList = ({setRankedData,prevGame}) => {
  const navigate= useNavigate();
  const [ratedList, setRatedList] = useState();
  const baseUrl = process.env.REACT_APP_BASE_URL 
  // || 'http://localhost:8080'
  const { token } = useContext(AuthContext);
  const {
    data:ratedListData,
    isLoading:ratedListDataLoading,
    error:ratedListDataError
  } = useQuery("ratedList",async()=>{
    const response = await axios.get(`${baseUrl}/api/rank/get-rated-list`,{
      headers:{
        'Authorization': `Bearer ${token}` 
      }
    })
    if(response && response.data ){
      setRankedData(response.data.ratedList)
      // console.log(response.data.ratedList)
      setRatedList(response.data.ratedList)
      // setRankedData(response.data.ratedList)
    }
  },
  {
    staleTime: Infinity,
    enabled:!!token // Set staleTime to Infinity
    // Other options...
  })
  if(ratedListDataError){
    if (ratedListDataError?.response?.status===401){
      navigate('/');
    }
  }
  const fadeAnimation = {
    // opacity: 0,
    // transform: 'translateX(10px)',
    animation: 'slide-right-animation 1s ease-in-out',
    animationFillMode: 'forwards',
  };

  const styles = `
    @keyframes slide-right-animation {
      0% {
        opacity: 0;
        z-index:0;
        transform: translateX(-20px);
      }
      100% {
        opacity: 1;
        z-index:2;
        transform: translateX(0);
      }
    }
  `;
    return (
        <>
        {/* <style>{styles}</style> */}
            <Box
            mx={2}
            sx={{
            display:{xs:'none',sm:'none',md:'flex'},
            flexDirection: "column",
            background: 'rgba(255, 255, 255, 0.01)',
            border: '0.8px solid rgba(255, 255, 255, 0.29)',
            backdropFilter: 'blur(10px)',
            borderRadius: '20px',
            justifyContent: "flex-start",
            width:'100%',
            transition:'0.2s',
            overflow:'hidden'
            
            // minHeight:'60vh'
          }}
        >
          <Box
            m={1}
            sx={{
              display:'flex',
              alignItems:'center',
              borderBottom: "1px Solid grey",
              overflow: "hidden",
              width: "100%",
              mx: "auto",
            }}
          >
            <Typography my={1} variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.8rem',sm: '1.4rem', md: '1.0rem' }, }}>
                        Your Top Rankings
            </Typography>
          </Box>
          
          <Box
            m={1}
            
            sx={{
              // border: "1px solid grey",
              height: "100%",
              width: "100%",
              mx: "auto",
              overflowX: "hidden",
              ...fadeAnimation
            }}
          >
            {ratedList &&
              ratedList?.slice(0, 20).map((rank, index) => (
                <Typography
                  // p={0.5}
                  p={0.05}
                  
                  key={index}
                  // align="left"
                  // variant="h5"
                  // noWrap
                  sx={{fontFamily: "'Inter', sans-serif",fontWeight:'400',textAlign:"start",overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',marginLeft:'5px',fontSize: {
                    xs: "0.9rem",
                    sm: "0.8rem",
                    md: "0.9rem",
                  },
                  opacity:rank?.gameName===prevGame?.gameName?'1':'0.47'
                  
                }}
                >
                  <span >{index + 1}. </span>
                  <Typography component='span' onClick={()=>navigate(`/game/${rank.slugName}`)} sx={{'&:hover': { textDecoration: 'underline' },cursor:'pointer',fontSize: {
                    xs: "0.9rem",
                    sm: "0.8rem",
                    md: "0.8rem",
                  }}}>{rank?.gameName}</Typography>
                </Typography>
              ))}
          </Box>
        </Box>
        </>
    );
};

export default RatedList;