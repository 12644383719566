import React from 'react';
import "../App.css"
const Logo = ({...rest}) => {
  const {noSpin} =rest
  return (
    <>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1520 1320"
    width="60"
    className={noSpin?'':'logo'}
    // {...props}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #fff;\n      }\n\n      .cls-1, .cls-2, .cls-3 {\n        fill-rule: evenodd;\n      }\n\n      .cls-2 {\n        fill: url(#New_Gradient_Swatch_3);\n      }\n\n      .cls-3 {\n        fill: url(#New_Gradient_Swatch_3-2);\n      }\n\n      .cls-4 {\n        fill: url(#New_Gradient_Swatch_3-3);\n      }\n    "
        }
      </style>
      <linearGradient
        id="New_Gradient_Swatch_3"
        data-name="New Gradient Swatch 3"
        x1={88.49}
        y1={635.98}
        x2={945.01}
        y2={635.98}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#be36fe" />
        <stop offset={0.33} stopColor="#be36fe" />
        <stop offset={0.76} stopColor="#3656ff" />
        <stop offset={1} stopColor="#3656ff" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_3-2"
        data-name="New Gradient Swatch 3"
        x1={1355.99}
        y1={801.38}
        x2={964.43}
        y2={575.32}
        xlinkHref="#New_Gradient_Swatch_3"
      />
      <linearGradient
        id="New_Gradient_Swatch_3-3"
        data-name="New Gradient Swatch 3"
        x1={356.35}
        y1={913.08}
        x2={1171.13}
        y2={913.08}
        xlinkHref="#New_Gradient_Swatch_3"
      />
    </defs>
    <g>
      <path
        className="cls-2"
        d="m309.77,1167.65l-10.55-11.72c-13.7-15.21-51.57-73.47-68.91-111.2-30.77-66.97-50.05-133.65-57.84-199.74l-3.87-57.14c-1.27-73.33,11.78-145.81,39.17-216.99,51.24-133.15,152.52-231.97,301.01-293.69,114.03-47.46,240.05-67.9,396.57-64.29l1.25.03c3.93.09,8.07.18,11.78.23,1.47-3.86,3.07-8.22,4.5-12.15,4.2-11.5,12.14-32.29,22.11-58.08-74.49-32.14-156.6-49.99-242.89-49.99-338.9,0-613.63,274.73-613.63,613.63,0,190.06,86.42,359.93,222.09,472.48-.32-3.77-.6-7.56-.81-11.38Z"
      />
      <path
        className="cls-3"
        d="m1213.97,425.62c14.17,52.38,28.41,104.91,43.57,160.82l32.93,121.46-218.93-144.76c-6.24-4.14-12.5-8.19-18.76-12.24-11.73-7.6-23.76-15.39-35.71-23.74-11.83,6.23-23.69,12.43-35.6,18.64,3.83,1.66,7.36,3.53,10.46,5.55l65.26,39.63c14.3,9.03,23.63,20.8,28.88,33.02,25.27,23.4,39.24,57.74,44.35,72.41,58.01,127.4,78.14,273.45,84.9,346.57,63.46-96.61,100.42-212.2,100.42-336.43,0-106.8-27.3-207.21-75.28-294.67l-26.49,13.74Z"
      />
      <path
        className="cls-1"
        d="m864.84,1079.49h-158.23c-20.49,0-38.48-5.39-53.68-13.39-10.21,68.77-59.98,120.93-70.51,131.29-38.38,41.55-67.59,68.56-90.53,85.82,65.58,23.91,136.38,36.97,210.23,36.97,101.33,0,196.91-24.58,281.14-68.07l-118.42-172.62Z"
      />
    </g>
    <g>
      <path
        className="cls-1"
        d="m326.83,1106.32c-61.31-121.87-63.82-246.8-15.84-372.82,26.03-68.41,64.78-129.31,123.93-174.82,72.32-55.59,156.13-80.76,245.46-87.92,21.22-1.69,41.28-2.64,61.38-3.27,59.98.6,51.49,62.62,51.49,62.62l39.28-99.27c-85.7-4.22-166.56-2.33-247.04,20.96-60.59,17.52-116.76,43.93-165.7,84.43-90.67,75.1-136.21,174.07-148.21,288.81-4.63,44.34-.93,89.47-.99,131.92-.03-.03-.07-.07-.07-.14-1.47-5.73-2.78-11.49-3.91-17.28-10.97-57.27-14.4-114.95-7.1-172.93,22.32-177.19,117.38-294.26,288.74-347.93,82.06-25.68,166.35-32.41,251.7-27.84,33.09,1.75,66.05,5.42,100.75,8.37-20.4,56.07-39.47,108.47-60.53,166.35,58.09-30.11,111.79-57.68,165.18-85.9,10.46-5.52,18.17-6.52,28.5,1.13,20.61,15.19,42.52,28.6,63.89,42.76,36.49,24.14,73.04,48.28,113.95,75.34-18.72-69.06-36.04-132.95-53.56-197.8,91.9-47.67,182.64-94.71,273.37-141.76-98.86-2.37-196.15-1.03-293.44,1.13-13.41.31-19.51-2.71-23.15-16.56-19.51-75.13-64.02-235.35-66.9-244.06-3.22,7.54-55.93,150.16-80.52,217.48-16.29,44.55-16.29,44.13-63.23,43.07-129.45-2.98-256.47,10.39-377.25,60.66-126.64,52.64-224.68,136.34-274.92,266.89-57.03,148.18-44.1,294.5,21.36,436.98,16.15,35.15,51.4,88.54,61.04,99.24-2.54-5.97-4.77-12.04-7.68-17.83ZM850.98,306.8c31.1-.1,62.17-.24,93.24-.31q40.19-.07,55.04-37.48c12.28-30.86,24.48-61.73,38.92-97.97,12.86,43.86,24.59,81.85,35.01,120.19,3.16,11.59,7.99,15.53,20.1,15.36,46.6-.79,93.21-.34,144.54-.38-7.13,5.21-83.33,49.31-118.68,66.7-16.01,7.92-18.07,16.43-12.76,32.17,9.77,29.11,17.49,58.91,27.64,93.75-32.44-21.4-61.35-38.92-88.4-58.91-15.95-11.83-28.57-12.93-45.54-2.26-26.34,16.53-54.28,30.42-87.03,48.42,15.05-38.17,27.98-70.92,41.73-105.72-35.77-23.9-103.97-72.29-103.8-73.56ZM270.66,957.08c1.27,1.27.34.82,0,0h0Z"
      />
      <path
        className="cls-4"
        d="m1086.24,713.99s-15.96-48.22-42.45-62.48c0,0,2.55-11.21-11.97-20.37l-65.45-39.73s-22.92-16.3-47.37,9.68c0,0-85.4-21.56-191.34-19.52l-19.52-18.42s-12.22-18.08-48.9-7.39c0,0-112.23,30.56-210.7,112.74,0,0-63.16,42.79-18.34,95.76l-51.61,192.87s-46.18,198.31-5.43,285.23c0,0,7.47,35.31,55.69,21.05,0,0,23.09,5.43,119.53-99.15,0,0,65.2-62.48,58.41-133.11,0,0-4.07-40.41-17.83-64.86l-59.08-127.85s80.48,120.72,112.06,154.33c0,0,21.9,39.22,64.69,39.22h307.65s22.05,2.21,39.73-23.43c0,0,62.82-82.68,66.89-90.15,0,0-39.39,98.47-59.76,131.07,0,0,74.02,170.46,17.66,205.78,0,0-20.23,8.83-44.14-12.22,0,0,40.07,58.4,86.25,14.26,0,0,50.26-51.61,50.26-166.01,0,0-7.47-208.87-84.89-377.29Zm-274.17,78.12l-42.64,1.31,2.04,41.53c.49,10.83-8.58,19.91-20.25,20.27l-31.99.98c-11.67.36-21.52-8.13-22.01-18.97l-4.65-39.59-42.64,1.31c-11.67.36-21.52-8.13-22.01-18.97l-1.33-29.7c-.49-10.83,8.58-19.91,20.25-20.27l42.64-1.31-1.78-47.08c-.49-10.83,8.58-19.91,20.25-20.27l31.99-.98c11.67-.36,21.52,8.13,22.01,18.97l4.39,45.14,42.64-1.31c11.67-.36,21.52,8.13,22.01,18.97l1.33,29.7c.49,10.83-8.58,19.91-20.25,20.27Zm189.54-122.26c11.61-5.19,26.54,2.94,33.35,18.17,6.81,15.23,2.93,31.78-8.68,36.97-11.61,5.19-26.54-2.94-33.35-18.17-6.81-15.23-2.93-31.78,8.68-36.97Zm1.19,120.25c-12.31,5.51-28.44-3.78-36.03-20.74-7.59-16.96-3.77-35.18,8.54-40.69,12.31-5.51,28.44,3.78,36.03,20.74,7.59,16.96,3.77,35.18-8.54,40.69Zm42.59,63.46c-13.09,2.67-26.55-9.12-30.06-26.34-3.51-17.22,4.26-33.34,17.35-36.01,13.09-2.67,26.55,9.12,30.06,26.34,3.51,17.22-4.26,33.34-17.35,36.01Zm22.98-65.69c-11.45,4.52-25.73-4.5-31.91-20.15-6.18-15.64-1.9-31.99,9.54-36.51,11.45-4.52,25.73,4.5,31.91,20.15,6.18,15.64,1.9,31.99-9.54,36.51Z"
      />
    </g>
  </svg>

      {/* <img src={require("../Assets/images/logoIcon.svg").default} style={{width:'60px'}} className='logo'/> */}

    </>
  );
};

export default Logo;