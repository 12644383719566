import { Box,Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const GameCard = ({game,...cardProps}) => {
  const navigate = useNavigate();
  const handleNameClick =async()=> {
    navigate(`/game/${game?.slugName}`)
    // window.location.reload();
    
  }
    return (
        <>
            <Box sx={{display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: "100%",}}>
                <Box m={1} sx={{display: 'flex',justifyContent:'flex-start',cursor:'pointer',}} {...cardProps} onClick={handleNameClick}>
                <img
                    src={`https://images.igdb.com/igdb/image/upload/t_cover_small/${
                      game?.coverUrl ? game.coverUrl : `undefined`
                    }.png`}
                    style={{ width: "80px", height: "100px",
                    filter: 'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.25))',
                    borderRadius: '10px',
                    // border: '2px solid white', 
                   }}
                    
                  />
                </Box>
                <Typography
                marginY={1}
                marginX={.5}
                onClick={handleNameClick}
                variant="h3"  component="div" sx={{ width:'100%',fontFamily: "'Inter', sans-serif",fontWeight:'400',textAlign:"start",fontSize: { xs:'0.9rem',sm: '1.2rem', md: '1.0rem' },opacity:'1','&:hover': { textDecoration: 'underline' },cursor:'pointer'}}>
                {game?.gameName?game.gameName:"Call of Duty: Modern Warfare II"}
              </Typography>
            </Box>
        </>
    );
};

export default GameCard;