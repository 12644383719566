import React, { useState, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../GlobalStyles/StyledInputSearch";
import debounce from "lodash/debounce";

const SearchBar = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");

  const debouncedSearch = useRef(debounce((text) => {
    onSearch(text);
  }, 1000)); // Adjust the debounce interval as needed

  const handleSearch = (text) => {
    setSearchText(text);
    debouncedSearch.current(text);
  };

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Search>
    </>
  );
};

export default SearchBar;
