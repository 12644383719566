import React from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios"
export const GameInfoHook = ({gameObjectId}) => {
    const baseUrl = process.env.REACT_APP_BASE_URL 

    const {data : companies ,isLoading : companyLoading,error: companyError} = useQuery (['company',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-game-companies`,{gameObjectId:gameObjectId})
        if (response && response.data && response.data.company){
          return response.data.company.map(company => company.companyName).join(', ');
        }
      },{staleTime:Infinity,retry:0})

      const {data : genres ,isLoading : genreLoading,error: genreError} = useQuery (['genre',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-game-genres`,{gameObjectId:gameObjectId})
        if (response && response.data && response.data.genres){
          return response.data.genres.map(genre => genre.themeName).join(', ');
        } 
      },{staleTime:Infinity})
      
      const {data : platforms ,isLoading : platformLoading,error: platformError} = useQuery (['platform',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-game-platforms`,{gameObjectId:gameObjectId})
        if (response && response.data && response.data.platforms){
          return response.data.platforms.map(platform => platform.platformName).join(', ');
        } 
      },{staleTime:Infinity,retry:0})
      const {data : media} = useQuery (['media',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-game-media`,{gameObjectId:gameObjectId})
        if (response && response.data ){
          return response.data.media
        } 
      },{staleTime:Infinity,retry:0,onSuccess:()=>{
        // setIsLoading(false)
      },})
      
      const {data : similarGames ,isLoading : similarGamesLoading,error: similarGamesError} = useQuery (['similarGames',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-similar-games`,{gameObjectId:gameObjectId})
        if (response && response.data ){
          console.log(response.data.similarGames)
          return response.data.similarGames
        } 
      },{staleTime:Infinity,retry:0,onSuccess:()=>{
        // setIsLoading(false)
      },})
      const {data : position ,isLoading : positionLoading,error: positionError} = useQuery (['position',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-game-position`,{gameObjectId:gameObjectId})
        if (response && response.data && response.data.rank){
          return response.data.rank
        } 
      },{staleTime:Infinity,retry:0})
      const {data : commentCount ,isLoading : commentCountLoading,error: commentCountError} = useQuery (['comments',gameObjectId],async ()=>{
        const response = await axios.post(`${baseUrl}/api/game/get-game-comments`,{gameObjectId:gameObjectId})
        if (response && response.data){
          console.log(response) 
          return response.data.commentCount
        } 
      },{staleTime:Infinity,retry:0})
    return {companies,genres,platforms,media,similarGames,position,commentCount}
};

