import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GameCard from "../Components/GameCard";
import GameCardSmall from "../Components/GameCardSmall";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import CircularProgress from '../GlobalStyles/Logo';
import { notifyError } from "../Helpers/Toasts";
import { ToastContainer } from "react-toastify";
import MediaCarousel from "../Components/MediaCarousel";

const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080";
const GameInfo = () => {
  const { slug } = useParams();
  const [gameExists,setGameExists] = useState(false)
  const [isLoading,setIsLoading] = useState(true)
  const[render,setRender]=useState(0)
  const navigate = useNavigate()
  const wordLimit = 400;
  const {
    data: game,
    isLoading: gameLoading,
    error,
    refetch
  } = useQuery(["game",slug], async () => {
    const response = await axios.get(`${baseUrl}/api/game/get-game/${slug}`);
    if (response && response.data && response.data.game) {
      console.log(response.data.game);
      
      return response.data.game;
    }
  } ,
  {
    retry:false,
    staleTime:Infinity,
    
    onSuccess:()=>{
      setGameExists(true)
      setIsLoading(false)
    },
    onError: (error) => {
      notifyError("No such Game found")
      setIsLoading(false)
      navigate('/')
      
    },
    
    
  });
  
  useEffect(()=>{
    // setIsLoading(true)
    console.log(`current slug: ${slug}`)
    if (game){
      setIsLoading(false)
    }
  },[slug])
  const {data : companies ,isLoading : companyLoading,error: companyError} = useQuery (['company',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-game-companies`,{gameObjectId:game?._id})
    if (response && response.data && response.data.company){
      return response.data.company.map(company => company.companyName).join(', ');
    }
  },{staleTime:Infinity,enabled:gameExists,retry:0})
  const {data : genres ,isLoading : genreLoading,error: genreError} = useQuery (['genre',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-game-genres`,{gameObjectId:game?._id})
    if (response && response.data && response.data.genres){
      return response.data.genres.map(genre => genre.themeName).join(', ');
    } 
  },{staleTime:Infinity,enabled:gameExists})
  const {data : platforms ,isLoading : platformLoading,error: platformError} = useQuery (['platform',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-game-platforms`,{gameObjectId:game?._id})
    if (response && response.data && response.data.platforms){
      return response.data.platforms.map(platform => platform.platformName).join(', ');
    } 
  },{staleTime:Infinity,enabled:gameExists,retry:0})
  const {data : media} = useQuery (['media',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-game-media`,{gameObjectId:game?._id})
    if (response && response.data ){
      return response.data.media
    } 
  },{staleTime:Infinity,enabled:gameExists,retry:0,onSuccess:()=>{
    // setIsLoading(false)
  },})
  
  const {data : similarGames ,isLoading : similarGamesLoading,error: similarGamesError} = useQuery (['similarGames',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-similar-games`,{gameObjectId:game?._id})
    if (response && response.data ){
      console.log(response.data.similarGames)
      return response.data.similarGames
    } 
  },{staleTime:Infinity,enabled:gameExists,retry:0,onSuccess:()=>{
    // setIsLoading(false)
  },})
  const {data : position ,isLoading : positionLoading,error: positionError} = useQuery (['position',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-game-position`,{gameObjectId:game?._id})
    if (response && response.data && response.data.rank){
      return response.data.rank
    } 
  },{staleTime:Infinity,enabled:gameExists,retry:0})
  const {data : commentCount ,isLoading : commentCountLoading,error: commentCountError} = useQuery (['comments',game?._id],async ()=>{
    const response = await axios.post(`${baseUrl}/api/game/get-game-comments`,{gameObjectId:game?._id})
    if (response && response.data){
      console.log(response) 
      return response.data.commentCount
    } 
  },{staleTime:Infinity,enabled:gameExists,retry:0})

  if (gameLoading || isLoading) {
    // Handle loading state, e.g. show a loading spinner
    return (
      <>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100vh',overflow:'hidden'}}>
      <CircularProgress/>
    </Box>
      </>
    );
  }

  if (error) {
    // Handle error state, e.g. show an error message
    return (
      <>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100vh',overflow:'hidden'}}>
      <Typography>Something Went Wrong</Typography>
    </Box>
      </>
    );
  }
  if (game) {
    const winPercent = (game?.timesWon / game?.timesRanked) * 100;
    const typographySx = {
      fontFamily: "'Inter', sans-serif",
      fontWeight: "300",
      textAlign: "center",
      fontSize: {
        xs: "1.2rem",
        sm: "1.0rem",
        md: "1.1rem",
        xl: "1.2rem",
      },
      lineHeight: "30px",
      border: "0.8px solid rgba(255, 255, 255, 0.29)",
      height: "95px",
    };
    const slideAnimation = {
      // opacity: 0,
     
      animation: 'slide-animation 0.6s ease-in-out',
      animationFillMode: 'forwards',
    };
  
    const styles = `
      @keyframes slide-animation {
        0% {
          opacity:0;
         max-height:0px;
          
        }
        100% {
          opacity:1;
          max-height:1500px;
        }
      }
    `;
    return (
      <>
      
      <Box sx={{display:'flex',position:'absolute',width:'100%',height:'100%',opacity:0.15,transition:'0.5s',flexDirection:{md:'row',xs:'column'},zIndex:-1,overflow:'hidden'}}>
                    <Box  sx={{display: 'flex',justifyContent:'center',width:'100%',height:'100%'}} >
                    <img
                        src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${
                        game?.coverUrl 
                        }.png`}
                        style={{ width: "100%", height: "100%",
                        filter: 'blur(15px)',
                        backgroundSize:'cover',
                        
                        // border: '2px solid white', 
                    }}  />
                    </Box>
      </Box>
        <Box
          sx={{
            position:'relative',
            display: "flex",
            flexDirection: "column",
            mx: "auto",
            width: { xs: "100vw", md: "90vw" },
            height:'auto',
            maxWidth: "1740px",
            overflow:'hidden'
          }}
        >
          <Typography
            mx={3}
            my={1}
            variant="h3"
            component="div"
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: "center",
              flexGrow: 1,
              fontFamily: "'Inter', sans-serif",
              fontWeight: "800",
              padding: "10px",
              textAlign: "center",
              fontSize: { xs: "2.0rem", sm: "2.4rem", md: "2.5rem" },
            }}
          >
            {game?.gameName || "Call of Duty: Modern Warfare 2"}
          </Typography>

          <Box
            mx={3}
            my={1}
            sx={{
              background: "rgba(255, 255, 255, 0.01)",
              border: "0.8px solid rgba(255, 255, 255, 0.29)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                borderBottom: " 0.8px solid rgba(255, 255, 255, 0.29)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: { xs: "column", md: "row" },
                  minWidth: { md: "446px" },
                }}
              >
                <Box>
                  <GameCard game={game} cardSx={{ width: "244px" }} titleSx={{fontSize:{xs:'1.2rem',sm:'1.2rem'}}} />
                </Box>
                {/* Game Type */}
                <Box
                  marginY={1}
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                    alignItems: "space-between",
                    flexDirection: { xs: "column", md: "column" },
                  }}
                >
                  <Box>
                    <Typography
                      m={1}
                      component="div"
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: "300",
                        textAlign: { xs: "center", md: "start" },
                        fontSize: {
                          xs: "1rem",
                          sm: "1.0rem",
                          md: "1.1rem",
                          xl: "1.2rem",
                        },
                      }}
                    >
                      <b>Themes </b> <br />
                      {genres || "Action"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      m={1}
                      component="div"
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: "300",
                        textAlign: { xs: "center", md: "start" },
                        fontSize: {
                          xs: "1rem",
                          sm: "1.0rem",
                          md: "1.1rem",
                          xl: "1.2rem",
                        },
                      }}
                    >
                      <b>Platforms </b> <br />
                      {platforms || "Playstation 5, PC, Xbox one"}
                    </Typography>
                  </Box>
                      {companies &&      
                  <Box>
                    <Typography
                      m={1}
                      component="div"
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: "300",
                        textAlign: { xs: "center", md: "start" },
                        fontSize: {
                          xs: "1rem",
                          sm: "1.0rem",
                          md: "1.1rem",
                          xl: "1.2rem",
                        },
                      }}
                    >
                      <b>Developer</b> <br />
                      {companies || "Activision"}
                    </Typography>
                  </Box>
                      }
                </Box>
              </Box>
              {/* summary */}
              <Box
                m={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "600px",
                  
                }}
              >
               <Typography
                m={1}
                component="div"
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "300",
                  textAlign: "center",
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1.3rem",
                    md: "1.2rem",
                    xl: "1.2rem",
                  },
                }}
              >
                {game?.summary && (
                  <>
                    {game.summary.length > wordLimit ? (
                      <>
                        {game.summary.substring(0, game.summary.indexOf(".", wordLimit) + 1)}
                        {game.summary.indexOf(".", wordLimit) === -1 ? "..." : ""}
                      </>
                    ) : (
                      game.summary
                    )}
                  </>
                )}
              </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                
              }}
            >
              <Box sx={{ width: { md: "20%" } }}>
                <Typography p={2} component="div" sx={{...typographySx,borderLeft:'0px',borderRight:'0px'}}>
                  <b>Rankings </b> <br />
                  {game?.timesRanked|| "0"}
                </Typography>
              </Box>

              <Box sx={{ width: { md: "20%" } }}>
                <Typography p={2} component="div" sx={{...typographySx,borderRight:'0px'}}>
                  <b>Win %</b> <br />
                  {winPercent?`Wins ${winPercent.toFixed(2)}%`:"-"}
                </Typography>
              </Box>

              <Box sx={{ width: { md: "20%" } }}>
                <Typography p={2} component="div" sx={{...typographySx,borderRight:'0px'}}>
                  <b>Position</b> <br />#{position|| "-"} all time
                </Typography>
              </Box>

              <Box sx={{ width: { md: "20%" } }}>
                <Typography p={2} component="div" sx={{...typographySx,borderRight:'0px'}}>
                  <b>Comments</b> <br />
                  {`${commentCount}` }
                </Typography>
              </Box>

              <Box sx={{ width: { md: "20%" } }}>
                <Typography p={2} component="div" sx={{...typographySx,borderRight:'0px'}}>
                  <b>Release Date</b> <br />
                  {game?.firstReleaseDate? 
                     moment.unix(game.firstReleaseDate).format('LL')
                  :"-"}
                </Typography>
              </Box>
            </Box>
            {media && media?.screenshots?.length!==0 &&
            <>
            <Typography
                component="div"
                px={3}
                mt={2}
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    sm: "1.5rem",
                    md: "1.4rem",
                    xl: "1.7rem",
                  },
                  border: "0px",
                  textAlign: "start",
                }}
              >
                <b>Screenshots & videos</b>
              </Typography>
            <Box my={2} sx={{width:'100%'}}>
                    <MediaCarousel media={media}/>
            </Box>
            </>
            }
            {
              similarGames && 
            <Box
              sx={{
                
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "center", md: "flex-start" },
                overflow:'hidden',
                ...slideAnimation,
              }}
            >
              <Typography
                component="div"
                px={3}
                mt={2}
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    sm: "1.5rem",
                    md: "1.4rem",
                    xl: "1.7rem",
                  },
                  border: "0px",
                  textAlign: "start",
                }}
              >
                <b>Similar Games</b>
              </Typography>
              <Box
                p={2}
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "100%",
                  flexDirection: { xs: "column", md: "row" },
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  textAlign: "left",
                  alignItems: "flex-start",
                  
                }}
              >
              
                {similarGames?
                  similarGames.map((game,idx)=>
                  <Box key={game.id} px={1} sx={{display:'flex', width: { md: "244px" } }}>
                  <GameCardSmall game={game} />
                </Box>
                  )
                :<></>
                }
                <Box sx={{flexBasis:'520px'}}>
                      
                    </Box>
                
              </Box>

              <Box
                p={2}
                sx={{
                  display: { xs: "flex", md: "none" },
                  width: "100%",
                  flexDirection: { md: "row" },
                  flexWrap: "wrap",
                  flexGrow: 1,
                  justifyContent: { xs: "space-between", md: "center" },
                  alignItems: "flex-start",
                }}
              >
                {similarGames?
                  similarGames.map((game)=>
                  <Box key={game.id} my={1} sx={{ maxWidth: { xs: "130px" } }}>
                  <GameCard game={game}  titleSx={{
                      fontSize: {
                        xs: "1.0rem",
                        sm: "1.0rem",
                        md: "1.4rem",
                        xl: "1.4rem",
                      },
                      opacity: "0.37",
                    }} />
                </Box>
                  )
                :<></>
                }
                
                
               
              </Box>
            </Box>
            }
          <ToastContainer/>
          </Box>
        </Box>
        <style>{styles}</style>
      </>
    );
  }
  
};

export default GameInfo;
