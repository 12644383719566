import {React,useEffect,useState} from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from 'react-router-dom';
import Leaderboards from './Leaderboards';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { TypographyStyles } from '../GlobalStyles/TypographyStyles';
const RankedInfoCard = ({ prevGame, ratedList }) => {
  const [render, setRender] = useState(0);
  const [containerState,setContainerState]=useState('leaderboard')
  const [gameExists, setGameExists] = useState(false);
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handlePrevGame = async(prevGame) => {
    setGameExists(true)
    await queryClient.fetchQuery('position');
  }
  const fadeAnimation = {
    opacity: 0,
    // transform: 'translateY(10px)',
    animation: 'slide-left-animation 0.5s ease-in-out',
    animationFillMode: 'forwards',
  };
  const {data:topUsers, isLoading:topUsersLoading,error:topUsersError} = useQuery(
    'topUsers',
    async () => {
      const response = await axios.get(`${baseUrl}/api/users/get-top-users`);
      if (response && response.data && response.data.topUsers){
        return response.data.topUsers
      }
    },{
      staleTime:10000
    }
  )
  const styles = `
    @keyframes slide-left-animation {
      0% {
        opacity: 0;
        transform: translateX(20px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `;

  // ...
  const handleRerank = ()=>{
    navigate(`/ranking/${prevGame.slugName}`)
  }
  
  const { data: position, isLoading: positionLoading, error: positionError, refetch } = useQuery(
    'position',
    async () => {
      // Use game object inside the fetch function
      const response = await axios.post(`${baseUrl}/api/game/get-game-position`, { gameObjectId: prevGame._id });
      if (response && response.data && response.data.rank) {
        
        return response.data.rank;
      }
    },
    {
      onSuccess: () => {
        // console.log(`success running`);
        // setGameExists(false);
      },
      enabled: !!gameExists // gameExists will be updated before the query runs
    },
    // {
    //   staleTime: Infinity, // Set staleTime to Infinity
    //   // Other options...
    // }
  );
  
  
  useEffect(() => {
    // console.log(`useEffect rankedInfo Card`);
    if (prevGame) {
      handlePrevGame(prevGame);
      console.log(ratedList)
      setContainerState("prevGame")
    }
    setGameExists(false)
    
    // console.log(`gameExists:${gameExists}`);
    // setRender((prevRender) => prevRender + 1);
  }, [prevGame]);
 
  // Use the refetch function to manually trigger a fetch of the query data
  // whenever prevGame changes
  // useEffect(() => {
  //   if (prevGame) {
  //     refetch();//calls global and personal rank
  //     // queryClient.fetchQuery('ratedList')//refetches rated list
  //   }
  // }, [prevGame]);
    
    const winPercent = ((prevGame?.timesWon / prevGame?.timesRanked) * 100).toFixed(2);
    return (
        <> 
        <style>{styles}</style>
          <Box
            mx={2}
            sx={{
              flexGrow:1,
              display:{xs:'none',sm:'none',md:'flex'},
              flexDirection: "column",
              justifyContent:'flex-start',
              background: 'rgba(255, 255, 255, 0.01)',
              border: '0.8px solid rgba(255, 255, 255, 0.29)',
              backdropFilter: 'blur(10px)',
              borderRadius: '20px',
              overflow:'hidden',
              minWidth:{md:'200px'},
              maxWidth:{md:'200px'}
              // minHeight:'60vh'
            }}
            >
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'100%'}}>

              
            {containerState==="prevGame" && prevGame ?
            
          <Box
           m={1}
            sx={{
              
              display:'flex',
              flexDirection:'column',
              
              // borderBottom: "1px Solid grey",
              width: "100%",
              mx: "auto",
            }}
          >
            <Box
            
            sx={{
              
              display:'flex',
              alignItems:'center',
              borderBottom: "1px Solid grey",
              overflow: "hidden",
              width: "100%",
              mx: "auto",
            }}
          >
            <Typography my={0.75} variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.8rem',sm: '1.4rem', md: '1.0rem' }, }}>
                        Previously Ranked
            </Typography>
          </Box>

          <Box sx={{display:'flex',flexDirection:'column',...fadeAnimation,}}>

          
            <Box m={1} sx={{display: 'flex',maxWidth: "105px",justifySelf:'center',alignSelf:'center',cursor:'pointer',}}>
                <img
                    src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${
                      prevGame?.coverUrl ? prevGame?.coverUrl : `nocover`
                    }.png`}
                    style={{ width: "100%", height: "100%",
                    // border: '2px solid #FFFFFF',
                    filter: 'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.25))',
                    borderRadius: '10px' }}
                    onClick={() => {
                    //  addRatedPair(leftCard.gameId, rightCard.gameId);
                    //   setRender(render + 1); 
                    }}
                  />
                </Box>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'8px',textAlign:"center",fontSize: { xs:'1.4rem',sm: '1.2rem', md: '0.8rem' },borderBottom:'1px solid grey' }}>
                        {prevGame?prevGame.gameName:"Call Of Duty : Modern Warfare 2 "}
            </Typography>
            <Typography variant="h3" component="div" sx={{  display:'flex',justifyContent:'center',alignItems:'center',flexGrow: 1, color: 'rgba(255, 255, 255, 0.37)', fontFamily: "'Inter', sans-serif", fontWeight: '400',
                        paddingY: '10px', textAlign: 'center', fontSize: { xs: '1.2rem', sm: '.8rem', md: '1.0rem' },
                        borderBottom: '1px solid grey', cursor: 'pointer', '&:hover': { textDecoration: 'underline' }}} onClick={()=>handleRerank()}>
                        Rank Again
                        <ArrowRightSharpIcon sx={{'&:hover': { textDecoration: 'underline' }}}/>
            </Typography>
            
                    {ratedList && prevGame &&
                    <>
            <Typography sx={{ display:'flex',justifyContent:'center',alignItems:'center',flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'6px',textAlign:"center",fontSize: { xs:'1.4rem',sm: '1.2rem', md: '1.0rem' },borderBottom:'1px solid grey' }} >
                <Typography component='span' sx={{fontSize:'inherit',wordWrap:'break-word'}} >
                    Global Rank :
                </Typography>
                <Typography component='span'  px={1} sx={{color:"#3656FF",fontWeight:'800',fontSize: { xs:'1.4rem',sm: '1.2rem', md: '1.0rem' },fontWeight:'700',}}>
                   # {position}
                </Typography>
            </Typography>
            
            
            <Typography sx={{ display:'flex',justifyContent:'center',alignItems:'center',flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'6px',textAlign:"center",fontSize: { xs:'1.4rem',sm: '1.2rem', md: '1.0rem' },borderBottom:'1px solid grey' }} >
                  <Typography  sx={{fontSize:'inherit'}}  >
                      Your Rank :
                  </Typography>
                  <Typography component='span' ml={1}  sx={{color:"#3656FF",fontWeight:'800',fontWeight:'700',fontSize: { xs:'1.4rem',sm: '1.2rem', md: '1.0rem' }}}>
                        # {ratedList?ratedList.findIndex((ratedGame) => prevGame.gameId === ratedGame.gameId) + 1:<></>} 
                  </Typography>
            </Typography>
                    </>
                    }
            
            
            {/* Insert winpercent>0 and exists logic here */}
            <Typography variant="h3" component="div" sx={{ display:'flex',justifyContent:'flex-end',flexGrow: 1, color: 'rgba(255, 255, 255, 0.37)', fontFamily: "'Inter', sans-serif", fontWeight: '400',
                        padding: '10px', textAlign: 'center', fontSize: { xs: '1.2rem', sm: '.8rem', md: '1.0rem' },
                        borderBottom: '1px solid grey',}}>
                {winPercent && !isNaN(winPercent) ? 
                `Wins ${winPercent}% of the time` : 
                <Typography >You're the <Typography component="span" sx={{color:"#3656FF",fontWeight:'800',fontSize: { xs:'1.4rem',sm: '1.2rem', md: '1.0rem' }}}>1<sup>st</sup></Typography> person to choose this game in a battle!</Typography>
                }
            </Typography>
            {/*  */}
            <Typography variant="h3" component="div" sx={{ flexGrow: 1, color: 'rgba(255, 255, 255, 0.37)', fontFamily: "'Inter', sans-serif", fontWeight: '400',
                        padding: '10px', textAlign: 'center', fontSize: { xs: '1.2rem', sm: '.8rem', md: '1.0rem' }
                        }}>
                        Ranked {prevGame?.timesRanked ? prevGame.timesRanked : 0} times
            </Typography>
            {/* <Typography sx={{display:'flex',justifyContent:'flex-end', flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'12px',textAlign:"flex",fontSize: { xs:'1.4rem',sm: '1.2rem', md: '1.6rem' },borderBottom:'1px solid grey' }} >   
                    Wins {winPercent} of the time
            </Typography> */}
            </Box>
          </Box>
          :
          containerState==="prevGame" &&
          <Typography my={1} variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.8rem',sm: '1.4rem', md: '1.0rem' }, }}>
                        Rank a Game
            </Typography>

          }
          {containerState==="leaderboard" &&
          <>
            <Box sx={{width:'100%',height:'100%'}}>
                       <Leaderboards data={topUsers} title="Top Global Users" titleSx={TypographyStyles.body2}/>
            </Box>
          </>
          }
        <Box component="div" sx={{display:'flex',justifyContent:'space-around', 
              }}>
        <LeaderboardIcon
              // fontSize="medium"
              sx={{
                marginBottom: "5px",
                cursor: "pointer",
                "&:hover": { color: "#FFFFFF",opacity:1 },
                color: "#FFFFFF",
                opacity: containerState === "leaderboard" ? 1 : .6,
                transition: "0.2s",
              }}
              onClick={() => setContainerState("leaderboard")}
            />
            <SportsEsportsIcon
              // fontSize="medium"
              sx={{
                
                marginBottom: "5px",
                cursor: "pointer",
                "&:hover": { color: "#FFFFFF",opacity:1 },
                color: "#FFFFFF",
                opacity: containerState === "prevGame" ? 1 : .6,
                transition: "0.2s",
              }}
              onClick={() => setContainerState("prevGame")}
            />
        </Box>
        </Box>
        </Box>
            
        </>
    );
};

export default RankedInfoCard;