
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";

import Navbar from "./Components/Navbar";

import GameRanking from "./Pages/GameRanking.jsx";
import GameInfo from "./Pages/GameInfo.jsx";
import UserInfo from "./Pages/UserInfo.jsx";
import Lander from "./Pages/Lander.jsx";
import Discussions from "./Pages/Discussions.jsx"
import Charts from "./Pages/Charts.jsx"

import { AuthProvider } from "./Contexts/AuthContext";
function App() {
  let darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
    typography: {
    fontFamily: "'Inter', sans-serif"
  },
  });
  
  return (
    <>
      <Router>
        <AuthProvider>
        
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Navbar />
              <Routes>
                <Route path="/" exact element={<Lander theme={darkTheme} />} />
                <Route path="/ranking" element={<GameRanking theme={darkTheme} />}/>
                <Route path="/ranking/:slug" element={<GameRanking theme={darkTheme} />}/>
                <Route path="/charts" element={<Charts theme={darkTheme} />}/>
                <Route path="/discussions" element={<Discussions theme={darkTheme} />}/>
                <Route path="/game/:slug" element={<GameInfo />} />
                <Route path="/user/:userName" element={<UserInfo />} />
              </Routes>
            </ThemeProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
