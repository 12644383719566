import {React,useState,useContext} from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useQuery } from "react-query";
import StyledButton from '../GlobalStyles/StyledButton';
import StyledTextField from '../GlobalStyles/StyledTextField';
import { AuthContext } from '../Contexts/AuthContext';
import { useNavigate, useNavigation } from 'react-router-dom';
const Leaderboards = ({data,title,...rest}) => {
  const {titleSx,value} = rest
    const navigate = useNavigate();
    
    
      const fadeAnimation = {
        opacity: 0,
        // transform: 'translateX(10px)',
        animation: 'slide-right-animation 0.5s ease-in-out',
        animationFillMode: 'forwards',
      };
    
      const styles = `
        @keyframes slide-right-animation {
          0% {
            opacity: 0;
            z-index:0;
            transform: translateX(-20px);
          }
          100% {
            opacity: 1;
            z-index:2;
            transform: translateX(0);
          }
        }
      `;
    return (
        <>
        <style>{styles}</style>
            <Box
            
            sx={{
                
            display:'flex',
            flexDirection: "column",
            zIndex:2,
            // background: 'rgba(255, 255, 255, 0.01)',
            // border: '0.8px solid rgba(255, 255, 255, 0.29)',
            // backdropFilter: 'blur(10px)',
            borderRadius: '20px',
            justifyContent: "flex-start",
            width:'100%',
            height:'100%',
            overflow:'hidden'
            // minHeight:'60vh'
          }}
        >
          {
            title && 
          <Box
           
            sx={{
              display:'flex',
              alignItems:'center',
              borderTop: "1px Solid grey",
              borderBottom: "1px Solid grey",
             
              width: "100%",
              mx: "auto",
              zIndex:1
            }}
          >
              <Typography my={1.2} variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.8rem',sm: '1.4rem', md: '1.0rem' },...titleSx }}>
                          {title}
              </Typography>
          </Box>
            }
          <Box
            m={1}
            
            sx={{
                
                ...fadeAnimation,
              // border: "1px solid grey",
             
              height: "100%",
              width: "100%",
              mx: "auto",
              overflowX: "hidden",
            }}
          >
            {data &&
              data?.slice(0, 20).map((element, index) => (
                <>
                <Box sx={{display:'flex',justifyContent:'space-between'}}> 
                <Typography
                component='div'
                  p={0.11}        
                  key={index}
                  sx={{display:'flex',flexDirection:'row',alignItems:'flex-start',fontFamily: "'Inter', sans-serif",fontWeight:'400',textAlign:"start",overflow: 'hidden',
                  textOverflow: 'ellipsis',
               
                  WebkitBoxOrient: 'vertical',marginLeft:'5px',fontSize: {
                    xs: "0.9rem",
                    sm: "0.8rem",
                    md: "0.9rem",
                  },
                  opacity:index===1? .87 : index>2 ? .47 : 1,
                  color: index === 0 ? '#FFD700' : index === 1 ? '#C0C0C0' : index === 2 ? '#CD7F32' : '#FFFFFF'

                }}
                >
                  
                  <Typography component='div' onClick={()=>navigate(`/user/${element?.userName}`)} sx={{'&:hover': { textDecoration: 'underline' },cursor:'pointer',fontSize: {
                    xs: "0.9rem",
                    sm: "0.8rem",
                    md: "0.8rem",
                  }}}> {index + 1}. {element?.userName}</Typography>
                  {index <3 && 
                  <>
                  <WorkspacePremiumIcon sx={{ color: index === 0 ? '#FFD700' : index === 1 ? '#C0C0C0' : index === 2 ? '#CD7F32' : '#FFFFFF',opacity:1,fontSize:'15px',marginX:'3px'}}/>   
                  </>
                  }
                </Typography>
                <Typography px={1} component='div' onClick={()=>navigate(`/user/${element?.userName}`)} sx={{'&:hover': { textDecoration: 'underline' },cursor:'pointer',fontSize: {
                    xs: "0.9rem",
                    sm: "0.8rem",
                    md: "0.8rem",
                    opacity:.47,
                  }}}>

                     {value==="monthly"?element.timesRankedMonthly:value==="weekly"?element.timesRankedWeekly:element.timesRanked}
                </Typography>
                
                </Box>
                </>
                
              ))}
          </Box>
        </Box>
        </>
    );
};

export default Leaderboards;