import { React, useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CommentBox from "../GlobalStyles/CommentBox";
import TextField from "@mui/material/TextField";
import Divider from '@mui/material/Divider';
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import SendIcon from '@mui/icons-material/Send';
import InfoBox from "./InfoBox";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { AuthContext } from "../Contexts/AuthContext";

const CommentContainer = ({ leftGame, rightGame }) => {
  const [containerState, setContainerState] = useState("comment");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { token } = useContext(AuthContext);
  const [comment, setComment] = useState('');
  const {
    data: comments,
    isLoading: commentsLoading,
    error: commentsError,
    refetch
  } = useQuery(
    "get-battle-comments",
    async () => {
      // Use game object inside the fetch function
      if(leftGame && rightGame){

        const response = await axios.get(
          `${baseUrl}/api/comments/get-comments/${leftGame.gameId}/vs/${rightGame.gameId}`,
          {
              headers: {
                  'Authorization': `Bearer ${token}` 
                }
          }
        );
        if (response && response.data) {
          // console.log(`comments : `+JSON.stringify(response.data.comments));
          if (response.data.comments.length ===0){
            setContainerState("info")
          }
          return response.data.comments;
        }
      }
    },
    {
      onSuccess: () => {
        // console.log(` Comment Query running`);
        // handleCommentRefresh(); 
        // setGameExists(false);
      },
      enabled:Boolean(leftGame && rightGame),
      staleTime: Infinity,
      // enabled: !!pairChanged,
    }
  );
  const postCommentMutation = useMutation(async ()=>{
    
      const response = await axios.post(`${baseUrl}/api/comments/post-comment`,{
        gameIds:[leftGame.gameId,rightGame.gameId],
        comment: comment
      },
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
    
  },{
      onSuccess:()=>{
        console.log('comment submitted successfully')
        setComment('');
        refetch()
      }
  })
  const handleCommentSubmit = (e) =>{
    // e.preventDefault();
    if (comment.length<150 && comment.length!=0){
      postCommentMutation.mutate();
      setContainerState("comment")
    }
    else{
      console.log("comment tooo long")
    }
    
  }
  // const handlePairChange = async()=>{
  //   setPairChanged(true, async () => {
  //     await queryClient.fetchQuery('get-battle-comments');
      
  //   });
  // }
  // const handleCommentRefresh =() => {
  //   if (pairChanged){
  //     // refetch();
  //   }
      
  //     console.log('refreshing comments')
  // };
  useEffect(() => {
    // if (leftGame || rightGame){
    //   handlePairChange()
    //   handleCommentRefresh();
    // }
    // console.log(leftGame,rightGame)
    // console.log(`container renerederd`);
    
    // console.log(`gameExists:${leftGame} and ${rightGame}`);
    // setRender((prevRender) => prevRender + 1);
    // refetch()
  }, []);
  useEffect(() => {
    // if (leftGame || rightGame){
    //   handlePairChange()
    //   handleCommentRefresh();
    // }
    // console.log(leftGame,rightGame)
    console.log(`left game and right game changed`);
    
    // console.log(`gameExists:${leftGame} and ${rightGame}`);
    // setRender((prevRender) => prevRender + 1);
    refetch()
  }, [leftGame, rightGame]);
  return (
    <>
      <Box
        m={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "rgba(255, 255, 255, 0.01)",
          border: "0.8px solid rgba(255, 255, 255, 0.29)",
          backdropFilter: "blur(10px)",
          borderRadius: "20px",
          flexGrow: 1,
        }}
      >
        <Box>
          <Box
            m={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ChatBubbleOutlineIcon
              fontSize="large"
              sx={{
                marginX: "7px",
                cursor: "pointer",
                "&:hover": { color: "#FFFFFF",opacity:1 },
                opacity: containerState === "comment" ? 1 : .6,
                transition: "0.2s",
              }}
              onClick={() => setContainerState("comment")}
            />
            <InfoOutlinedIcon
              fontSize="large"
              sx={{
                marginX: "7px",
                cursor: "pointer",
                "&:hover": { color: "#FFFFFF",opacity:1 },
                opacity: containerState === "info" ? 1 : .6,
                transition: "0.2s",
              }}
              onClick={() => setContainerState("info")}
            />
            <Box mt={1} sx={{display:'flex',justifyContent:'center',alignItems:'center', width: "100%", paddingRight: "15px" }}>
              <TextField
                required
                fullWidth
                name="comment"
                // label="Add a comment"
                placeholder="Add a comment..."
                id="comment"
                value={comment} // set the value of the comment input to the state variable
                onChange={(e) => setComment(e.target.value)} // update the state variable as the user types
                labelcolor="rgba(255, 255, 255, 0.9)"
                variant="standard"
              />
              
              <SendIcon sx={{
                marginX: "7px",
                cursor: "pointer",
                color:'grey',
                "&:hover": { color: "#FFFFFF" },
                
                transition: "0.2s",
              }}
              onClick={()=>handleCommentSubmit()}
              />
             
            </Box>
          </Box>
          {containerState === "comment" && (
            <>
              <Box
                py={0.5}
                px={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", sm: "space-between" },
                  alignItems: { xs: "flex-start", md: "center" },
                  flexDirection: { xs: "column", md: "row" },
                  marginLeft: { xs: "5px" },
                }}
              >
                {comments && comments?.length!=0?
                  <>
                  <Box sx={{display:'flex',justifyContent: { xs: "flex-start", sm: "space-around" },flexGrow:1,
                  alignItems: { xs: "flex-start", md: "flex-start" },
                  flexDirection: { xs: "column", md: "row" },
                  marginLeft: { xs: "5px" },}}>

                 {comments.slice(0,4).map((comment)=>
                  <Box sx={{maxWidth:'244px'}}> 
                    <CommentBox comment={comment}/>
                    </Box>
                    )}
                    </Box>
                <Typography
                  mt={-1.5}
                  mb={0.5}
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    alignSelf:'flex-end',
                    // flexGrow: 1,
                    color: "rgba(255, 255, 255, 0.37)",
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: "400",
                    textAlign: "center",
                    fontSize: { xs: "1.2rem", sm: ".8rem", md: "1.0rem" },
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  See all
                  <ArrowRightSharpIcon
                    sx={{ "&:hover": { textDecoration: "underline" } }}
                  />
                </Typography>
                  </>
                :<>
                  <Typography m={2} variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.6rem',sm: '1.0rem', md: '1.0rem' }, }}>
                        Be the first to post a comment
                    </Typography>
                </>}
                {/* <CommentBox />
                <CommentBox />
                <CommentBox />  */}
              </Box>
            </>
          )}
          {containerState === "info" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: { xs: "center", sm: "space-between" },
                 
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", sm: "stretch" },
                }}
              >
                <Box sx={{width:{md:'50%'}}}>
                <InfoBox game={leftGame} />
                </Box>

                {/* <Box
                  sx={{
                    display: { xs: "none", md: "block" },
                    width: "1px",
                    height: "130px",
                    background: "rgba(255, 255, 255)",
                    border: "1px solid white",
                  }}
                /> */}
                <Box sx={{ width:'2px',background: "linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.0) 100%)" }}>
            <Divider orientation="vertical" flexItem />
          </Box>
              <Box sx={{width:{md:'50%'}}}>
                <InfoBox game={rightGame}/>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CommentContainer;
