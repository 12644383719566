import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as PaypalIcon } from "../Assets/images/paypal.svg";
import { TypographyStyles } from "../GlobalStyles/TypographyStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
const Donate = () => {
  return (
    <>
      <Card onClick={()=>window.open(`https://www.paypal.com/donate/?hosted_button_id=ES8FPXAD972UQ`)}>
        <CardActionArea>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "310px",
              border: "0.8px solid rgba(255, 255, 255, 0.29)",
              borderRadius: "10px",
            }}
          >
            <Typography
              my={2}
              sx={{
                ...TypographyStyles.body2,
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
              }}
            >
              This Platform is not monetized!
            </Typography>
            {/* <Box p={0.5} sx={{display:'flex',alignItems:'center',justifyContent:'center',background:'rgba(255,255,255,0.06)',borderRadius:'999px','&:hover':{background:'rgba(255,255,255,0.2)',cursor:'pointer'}}}> */}
            <PaypalIcon />
            {/* </Box> */}
            <Typography
              my={2}
              sx={{
                ...TypographyStyles.caption,
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
              }}
            >
              Consider Donating to help us make more improvements and add new
              features
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
};

export default Donate;
