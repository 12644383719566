import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
export const UserStats = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { data: topUsers } = useQuery(
    "topUsers",
    async () => {
      const response = await axios.get(`${baseUrl}/api/users/get-top-users`);
      if (response && response.data && response.data.topUsers) {
        return response.data.topUsers.splice(0, 15);
      }
    },
    {
      staleTime: 10000,
    }
  );
  const { data: topMonthlyUsers } = useQuery(
    "topMonthlyUsers",
    async () => {
      const response = await axios.get(
        `${baseUrl}/api/users/get-top-monthly-users`
      );
      if (response && response.data && response.data.topMonthlyUsers) {
        return response.data.topMonthlyUsers.splice(0, 15);
      }
    },
    {
      staleTime: 10000,
      retry: 0,
    }
  );
  const { data: topWeeklyUsers } = useQuery(
    "topWeeklyUsers",
    async () => {
      const response = await axios.get(
        `${baseUrl}/api/users/get-top-weekly-users`
      );
      if (response && response.data && response.data.topWeeklyUsers) {
        return response.data.topWeeklyUsers.splice(0, 15);
      }
    },
    {
      staleTime: 10000,
      retry: 0,
    }
  );
  return { topUsers, topMonthlyUsers, topWeeklyUsers };
};
