import {React,useState,useContext,useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StyledButton from '../GlobalStyles/StyledButton';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";
import Logo from '../GlobalStyles/Logo';
import axios from 'axios'
import SearchBar from './SearchBar';
import SearchCardSmall from "../Components/Search/SearchCardSmall"
import CircularProgress from '../GlobalStyles/Logo';
// import Logo from "../GlobalStyles/Logo"
const Navbar = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const {userName,handleLogout} = useContext(AuthContext);
  const [gamesList,setGamesList] = useState()
  const [activePage,setActivePage] = useState()
  const location = useLocation()
  const [isGameListBoxOpen, setIsGameListBoxOpen] = useState(false);
  const [searchLoading,setSearchLoading] = useState(true)
  const { slug } = useParams();
  
  const handleSearch = async (searchText) => {
    try {
      if (searchText===""){
        setGamesList(undefined)
        return
      }
      setSearchLoading(true)
      const response = await axios.post(`${baseUrl}/api/game/get-games-by-search`, {
        searchText: searchText,
      });

      const data = response.data;

      if (data.status) {
        console.log("Games Found:", data.games);
        setGamesList(data.games)
        setSearchLoading(false)
      } else {
        console.log("Game not found");
        setSearchLoading(false)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
    const typographySx={ 
        flexGrow: 1,
        fontFamily: "'Inter', sans-serif",
        marginX: { sm: '0.5rem', md: '0.9rem' },
        fontSize: { sm: '1.2rem', md: '1.3rem' },
        cursor:'pointer',
        "&:hover":{
            transform: 'scale3d(1.1, 1.1, 1.1)',
        } ,
        fontWeight:'700',
        transition:'0.1s',
        userSelect:'none'
    }
    const buttonSx={
     
      fontSize: { xs:'0.7rem',sm: '0.9rem', md: '0.8rem' ,xl:'1.0rem'},
      // padding:{xs:'5px 10px 5px 10px',sm:'5px 25px 5px 25px'},
      flexGrow: 1,
        fontFamily: "'Inter', sans-serif",
        marginX: { sm: '0.5rem', md: '0.9rem' },
        fontSize: { sm: '1.2rem', md: '1.3rem' },
        cursor:'pointer',
        "&:hover":{
            transform: 'scale3d(1.1, 1.1, 1.1)',
        } ,
        transition:'0.1s',
      backgroundColor:"transparent",
      // '&:hover': { color: 'rgba(255, 255, 255, 1)',backgroundColor:"transparent", }
    }
    const {params} = useParams()
    const handleUserButtonClick = ()=>{
      if (userName){
        navigate(`/user/${userName}`)
      }
      else{
        navigate('/?lander=login')
      }
    }
    const handleRankingClick = ()=>{
      if (userName){
        navigate('/ranking')
      }
      else{
        navigate('/?lander=signup')
      }
    }
    const handleChartsClick = () =>{
      navigate('/charts')
    }
    const handleDiscussionsClick = () =>{
      navigate('/discussions')
    }
    
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    
    return (
       <>
        <Box sx={{position:'relative',zIndex:5, flexGrow: 1  }}>
      <AppBar position='static' sx={{background: 'rgba(0, 0, 0, 0.1)'}}>
        <Toolbar sx={{display:'flex',justifyContent:{xs:'space-between',md:'space-evenly'},width:'100%'}}>
         
           <Box sx={{  display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              
                <MenuItem  onClick={handleCloseNavMenu}>
                  <Typography variant="h6" component="div" sx={{...typographySx,...buttonSx}} onClick={()=>handleRankingClick()}>
                    Rank Games
                  </Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                  <Typography variant="h6" component="div" sx={{...typographySx,...buttonSx}} onClick={()=>handleChartsClick()}>
                    Charts
                  </Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                  <Typography variant="h6" component="div" sx={{...typographySx,...buttonSx}} onClick={()=>handleDiscussionsClick()}>
                    Discussions
                  </Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                  <Typography variant="h6" component="div" sx={{...typographySx,...buttonSx}} onClick={()=>handleLogout()}>
                    Logout
                  </Typography>
                </MenuItem>
              
            </Menu>
          </Box>
            <Box sx={{display:'flex',justifyContent: 'center', alignItems: 'center',cursor:'pointer',width:{md:'30%'} }} onClick={()=>navigate('/')} >
                <Box sx={{ height: '100%',   borderRadius: '50%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',maxWidth:'40px' }}>
                      {/* <img src={require("../Assets/images/logoIcon.svg").default} style={{width:'60px'}}/> */}
                      <Logo noSpin={true}/>
                    </Box>  
                <Typography variant="h6" component="div" sx={{ fontFamily: "'Inter', sans-serif",fontWeight:'800',paddingX:'5px' }}>
                    Playchart
                </Typography>
            </Box>
                
            <Box sx={{ display: {xs:'none',md:'flex'},flexGrow:1,maxWidth:'800px', fontWeight: '300', color: '#FFFFFF',textAlign:'center',width:{md:'30%'}}}>
                <Typography variant="h6" component="div" sx={{...typographySx,...buttonSx}} onClick={()=>handleRankingClick()}>
                    Rank Games
                </Typography>
                <Typography variant="h6" component="div" sx={typographySx} onClick={()=>handleChartsClick()}>
                    Charts
                </Typography>
                <Typography variant="h6" component="div" sx={typographySx} onClick={()=>handleDiscussionsClick()}>
                    Discussions
                </Typography>
                
            </Box>

            <Box sx={{display:'flex',width:{md:'30%'},justifyContent:'center',position:'relative',maxHeight:'35px'}}  >
              <Box mx={1} sx={{}}  onClick={() => setIsGameListBoxOpen(true)} 
                  >
                <SearchBar onSearch={handleSearch}/>
                
                {
                  gamesList && isGameListBoxOpen && gamesList.length!==0 && !searchLoading &&
                  <Box 
                  
                  ml={1} sx={{background: 'rgba(255, 255, 255, 0.01)',border: '0.8px solid rgba(255, 255, 255, 0.29)',backdropFilter: 'blur(10px)',
                  borderRadius: '5px',display:'flex',flexDirection:'column',zIndex:60,maxWidth:'240px',alignItems:'center',maxHeight:'300px',overflowY:'scroll',overflowX:'hidden'}} >
                    {
                      gamesList.map((game)=>
                        <SearchCardSmall game={game}/>
                      )
                    }
                  </Box>
                }
                {
                   searchLoading && isGameListBoxOpen &&
                  <Box 
                  
                  ml={1} sx={{background: 'rgba(255, 255, 255, 0.01)',border: '0.8px solid rgba(255, 255, 255, 0.29)',backdropFilter: 'blur(10px)',
                  borderRadius: '5px',display:'flex',flexDirection:'column',zIndex:60,maxWidth:'240px',alignItems:'center',maxHeight:'300px',overflowY:'scroll',overflowX:'hidden'}} >
                    <CircularProgress/>
                  </Box>
                }
                
              </Box>
                <StyledButton
                text={userName?'Your Profile':'Login'}
                onClick={()=>
                  handleUserButtonClick()}
                sx={{backgroundColor:'#3656FF'}}
                />
            </Box>
        </Toolbar>
      </AppBar>
    </Box>
       </>
    );
};

export default Navbar;