import { React, useState, useEffect,useContext ,useRef,useLayoutEffect} from "react";
import axios from "axios";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { useQuery, useMutation, useQueryClient } from "react-query";
import CircularProgress from '../GlobalStyles/Logo';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Divider, Typography } from "@mui/material";
import StyledButton from "../GlobalStyles/StyledButton";
import GameCard from "./GameCard";
import { AuthContext } from '../Contexts/AuthContext';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { notifyError } from "../Helpers/Toasts";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const Rank = ({setPrevGame,setLeftGame,setRightGame}) => {
  
  const baseUrl = process.env.REACT_APP_BASE_URL 
  // || 'http://localhost:8080'
  const { token } = useContext(AuthContext);
  const navigate= useNavigate();
  const queryClient = useQueryClient();
  const [leftCard, setLeftCard] = useState();
  const [rightCard, setRightCard] = useState();
  const [render, setRender] = useState(0);
  const [isLoading,setIsLoading] = useState(true)
  const [isLeftLoading,setIsLeftLoading]=useState(false)
  const [isRightLoading,setIsRightLoading]=useState(false)
  const [winId, setWinId] = useState();
  const [lossId, setLossId] = useState();
  const [isReranking,setIsReranking] = useState (false)
  const [selectedDecade, setSelectedDecade] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 220,
      },
    },
  };
  const inputSx={
    color: 'white',
    '&.Mui-focused': {
        color: 'white',
    },
  }
  const selectSx={
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
  "&.MuiSelect-root:focus .MuiChip-root": {
    backgroundColor: "#f0f0f0",
    color: "black"
  }
}
const infoSx={
  position:{sm:'absolute'},
  width:{xs:'25px',sm:'25px'},
  bottom:'0px',
  cursor:'pointer',
  zIndex:4,
  margin:'5px',
  opacity:0.57,
  "&:hover": { color: "#FFFFFF",opacity:1 },

}
  const gameCardSx={
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center',
      alignSelf: 'stretch',
      width: "100%",
      height:'100%',
      "&:hover": {
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 100%, rgba(255, 255, 255, 0) 0%)", 
        transition: "background-color 0.3s ease-in-out",
        transform:'scale3d(1.02,1.02,1.02)',
        transition: "transform 0.1s ease-in",
      },
      "&:hover img": {
        outline: '0.5px solid rgba(255, 255, 255, 1)',

        transition: "transform 0.1s ease-in",
      },
      zIndex:3
  }
  const gameCoverSx={
    display:{xs:'flex',sm:'block'},
    flexDirection:{xs:'column-reverse',sm:'column'},
    justifyContent:'space-between',
    
    alignItems:{sm:'flex-start'},
    position:'relative',
    width:'100%',
   
    zIndex:2,
    "&:hover .gameBox": {
      background: "linear-gradient(220deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0) 60%)", 
      transition: "background-color 0.3s ease-in-out",
      transform:'scale3d(1.02,1.02,1.02)',
      transition: "transform 0.1s ease-in",
    },
    "&:hover img": {
      outline: '2px solid rgba(255, 255, 255, 1)',
      
      transition: "transform 0.1s ease-in",
    },
   
  }
  const ButtonSx={
    marginY:'8px',
    fontSize: { xs:'0.7rem',sm: '0.9rem', md: '0.8rem' ,xl:'1.0rem'},
    padding:{xs:'5px 10px 5px 10px',sm:'5px 15px 5px 15px'},
    border:"2px solid rgba(225, 225, 225, 0.5)",
    backgroundColor:"transparent",
    '&:hover': { color: 'rgba(255, 255, 255, 1)',backgroundColor:"transparent",border:"2px solid rgba(255, 255, 255, 1)", }

  }

  // const cardRef = useRef(null);


  // const [cardHeight, setCardHeight] = useState(0);
  // console.log(`card Height: ${cardHeight}`)
  // useLayoutEffect(() => {
  //   // setWidth(ref.current.offsetWidth);
  //   if(leftCard && rightCard){

  //     setCardHeight(cardRef.current.offsetHeight);
  //   }
  // }, []);
  
  const [platforms, setPlatforms] = useState([]);
  const [genres, setGenres] = useState([]);
  const [genresList, setGenresList] = useState([]);
  const platformList = {
    PC: [6],
    Xbox: [11, 12, 49, 169],
    Playstation: [48, 46, 38, 7, 8, 9, 167],
    Nintendo: [4, 18, 19, 20, 21, 37, 47, 130, 131, 137, 159, 461],
    VR: [161, 162, 163, 165, 388, 386, 385, 384, 386, 387, 390],
    Mobile: [34, 39, 405],
    Retro: [
      22, 23, 24, 29, 30, 33, 35, 52, 58, 59, 60, 61, 63, 64, 65, 66, 78, 309,
    ],
  };
  const decadeList = [1970, 1980, 1990, 2000, 2010, 2020];
  let decadeListFiltered = decadeList;
  if (platforms.includes("VR")) {
    decadeListFiltered = decadeList.filter((decade) => decade >= 2010);
  }
  // functions involved :
  //     filters :
  //       1. handle platform change
  //       2. handle genre change
  //       3. handle decade change
  //     apis :
  //       1. get rated list
  //       2. add rated pair
  //       3. get new game
  //       4. get all genres
  const {slug} = useParams()
  const {
    data: gameToRank,
    isLoading: isSlugGameLoading,
    error: slugError,
    refetch,
  } = useQuery(["game", slug], async () => {
    const response = await axios.get(`${baseUrl}/api/game/get-game/${slug}`);
    if (response && response.data && response.data.game) {
      console.log(response.data.game);
      return response.data.game;
    }
  }, {
    staleTime: Infinity,
    enabled: isReranking,
    retry: 1, // Set retry to 1 for a single retry attempt
    onError: (error) => {
      handleStopRerank()
    },
  });

  
  useEffect(()=>{
    if(slug){
      console.log(`slug changed to ${slug}`)
      setIsReranking(true)
      setRender(render+1)
    }
    // else{
    //   console.log('handling stop rerank')
    //   handleStopRerank()
    // }
  },[slug])

  const handleStopRerank= () =>{
    console.log("handling stop rerank")
    setIsReranking(false)
    setRender(render+1)
    navigate('/ranking')
  }
  
  const {
    data: genreQuery,
    isLoading: isLoadingGenres,
    error: genresError,
  } = useQuery(
    "genres",
    async () => {
        const response = await axios.get(
          `${baseUrl}/api/rank/get-all-genres`, {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
          }
        );
  
        if (response && response.data && response.data.genresToFind) {
          setGenresList(response.data.genresToFind) 
          console.log( response.data.genresToFind)
        }
    },
    {
      staleTime: 50000,
      onError: (error) => {
        notifyError("Something went wrong please refresh the page")
        navigate('/')
      },
      enabled:!!token // Set staleTime to Infinity
    }
  );
  const { data: newGameData, isLoading: isLoadingNewGameData, error: newGameDataError } = useQuery('newGame', async() =>{
    try {
      // let gamesRanked = ratedList?.length;
      let filters = {
        ...(platforms.length !== 0 && {
          platforms:
            platforms.flatMap((platform) => platformList[platform]) || [],
        }),
        ...(genres.length !== 0 && {
          genres:
            genres.map(
              (genre) => genresList.find((g) => g.genreName === genre).genreId
            ) || [],
        }),
        ...(selectedDecade && {
          decade: selectedDecade,
        }),
      };
      if (Object.keys(filters).length === 0) {
        filters = null;
      }
      if(slug){
        filters={rerank:true}
      }
      const response = await axios.post(
        `${
          baseUrl
        }/api/rank/get-new-game`,
        {
          filters,
          // gamesRanked
        },
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        }
      );
      return response.data;
      
    } catch (err) {
      console.log(err);
    }
  },
  {
    staleTime: Infinity, // Set staleTime to Infinity
    retry: 2,
    enabled:!!token
    // Other options...
  } 
    
  )
  if(newGameDataError){
    navigate('/ranking')
  }
  const addRatedPairMutation = useMutation(async(data)=>{
      try {
        console.log(data.winId,data.lossId)
        const rerank = isReranking ? true : undefined;
        const response = await axios.post(
          `${
           baseUrl
          }/api/rank/add-rated-pair`,
          {
            winId: data.winId,
            lossId: data.lossId,
            rerank:rerank
          },
          {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
          }
        );
        
      } catch (err) {
        console.log(err);
      }
      
    },{
      onSuccess: () => {
        console.log(`setting prev game`)
        // await queryClient.refetchQueries('position');
        // Custom onSuccess logic here
      },
      onError: (error) => {
        console.log('Mutation error:', error); // Custom onError logic here
      },
      enabled:!!token
    }
    
    )
  const handleDuplicate = async (newLeftCard, newRightCard) => {

    console.log(`duplicated being checked for ${newLeftCard.gameId} & ${newRightCard.gameId}`);
    // console.log(newRightCard.gameId);
    if (newLeftCard.gameId === newRightCard.gameId) {
      setRender(render + 1);
      console.log("duplicate detected")
    }
  };
  // if(genres.isLoading){
  //   return <><CircularProgress/></>
  // }
  if(genresError || newGameDataError){
      navigate('/');
    
  }
  const handleLeftInfoIconClick = ()=>{
    window.open(`/game/${leftCard.slugName}`, 'rel=noopener noreferrer')
  }
  const handleRightInfoIconClick = ()=>{
    window.open(`/game/${rightCard.slugName}`, 'rel=noopener noreferrer')
  }
    // Handler for right card button click
    const handleRightCardClick = async () => {
      setIsLoading(true)
      // Call addRatedPairMutation to add rating for right card
      await addRatedPairMutation.mutateAsync({winId:rightCard.gameId,lossId: leftCard.gameId});
      // await queryClient.fetchQuery('position')
      setPrevGame(rightCard) 
      // fetchData();
      setRender(render=>render+1)
    
    };
  const handleLeftCardClick = async () => {
    setIsLoading(true)
    // Call addRatedPairMutation to add rating for left card
    await addRatedPairMutation.mutateAsync({winId:leftCard.gameId, lossId:rightCard.gameId});
    // await queryClient.fetchQuery('position')
    setPrevGame(leftCard) 
    setRender(render=>render+1)
    // fetchData();
  };
  const handleNotPlayed = async (card) =>{
    
    if (card === leftCard){
      setIsLeftLoading(true)
      console.log('left not played')
      const newLeftCard = await queryClient.fetchQuery('newGame');
      await handleDuplicate(newLeftCard, rightCard);
      setLeftCard(newLeftCard)
      setLeftGame(newLeftCard)
      setIsLeftLoading(false)
    }
    else if(card === rightCard){
      setIsRightLoading(true)
      console.log('right not played')
      const newRightCard = await queryClient.fetchQuery('newGame');
      await handleDuplicate(leftCard, newRightCard);
      setRightCard(newRightCard)
      setRightGame(newRightCard)
      setIsRightLoading(false)
    }
    else{
      fetchData();
    }
  }

  const fetchData = async () => {
    console.log(`fetch running`)
    setIsLoading(true)
    let newLeftCard;
    if (!slug){
      console.log('not reranking so  calling left game');
      newLeftCard = await queryClient.fetchQuery('newGame');
    }
    else{
      newLeftCard = await queryClient.fetchQuery(["game",slug])
      
      console.log(gameToRank)
    }
    const newRightCard = await queryClient.fetchQuery('newGame');
    if (newLeftCard && newRightCard){
      await handleDuplicate(newLeftCard, newRightCard);
      setLeftCard(newLeftCard);
      setRightCard(newRightCard);
      setIsLoading(false)
      setLeftGame(newLeftCard);
      setRightGame(newRightCard);
  
      await queryClient.fetchQuery('ratedList');
      
    }
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  const handlePlatformChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(typeof value);
    let platformValues = [];
    if (typeof value === "object" && value !== null) {
      // Extract the values of the object's keys and store them in an array
      if (Object.values(value) == "VR" && selectedDecade < 2015) {
        setSelectedDecade("");
        setGenres([]);
      }
      platformValues = Object.values(value);
      // setPlatformIds(platformValues)
    }
    setPlatforms(platformValues);
    console.log(platformValues)
    setRender(render + 1);
  };
  const handleGenreChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    let genresValues = [];
    if (typeof value === "object" && value !== null) {
      // Extract the values of the object's keys and store them in an array
      genresValues = value;
      // setPlatformIds(platformValues)
    }
    setGenres(genresValues);

    setRender(render + 1);
  };
  const handleDecadeChange = (event) => {
    if (event.target.value < 1980) {
        setGenres([]);
    }
    if (event.target.value < 1990) {
      setPlatforms([]);
    }
    setSelectedDecade(event.target.value);
    setSelectedYear(null);
    setRender(render + 1);
  };
  const handleClearFilters = ()=>{
    setPlatforms([]);
    setGenres([]);
    setSelectedDecade();
    setRender(render+1)
  }
    return (
        <>
            <Box  sx={{display: "flex",flexDirection: "column",justifyContent: "flex-start",alignItems:'center',
            background: 'rgba(255, 255, 255, 0.01)',border: '0.8px solid rgba(255, 255, 255, 0.29)',backdropFilter: 'blur(10px)',
            borderRadius: '20px',width: "100%",overflow: "hidden",
            }}>
                <Box  sx={{ borderBottom: "1px Solid grey",overflow: "hidden",width: "100%",mx: "auto",background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%)"}}>
                    <Typography variant="h3" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'600',padding:'10px',textAlign:"center",fontSize: { xs:'1.6rem',sm: '1.9rem', md: '2.0rem' }, }}>
                        Which is your favorite?
                    </Typography>
                </Box>
                <Box
                    sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems:'center',
                    borderBottom: "1px solid grey ",
                    width: "100%",
                    maxHeight:'40px'
                    }}
                >
            {isReranking?
            <>
            <Box sx={{display:'flex',justifyContent:'space-around',width:'100%'}}>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'400',padding:'10px',textAlign:"center",fontSize: { xs:'0.9rem',sm: '0.7rem', md: '1.0rem' }, textOverflow: 'ellipsis',overflow: 'hidden',
    whiteSpace: 'nowrap',}}>
                        <b>Ranking</b> :  {gameToRank?.gameName}
              </Typography>
                    <Typography variant="h3" component="div" sx={{  display:'flex',justifyContent:'center',alignItems:'center',flexGrow: 1, color: 'rgba(255, 255, 255, 0.37)', fontFamily: "'Inter', sans-serif", fontWeight: '400',
                         textAlign: 'center', fontSize: { xs: '0.9rem', sm: '0.9rem', md: '1.0rem' },
                         cursor: 'pointer', '&:hover': { textDecoration: 'underline' },minWidth:'100px'}} onClick={()=>handleStopRerank()}>
                        Stop Ranking
                        
            </Typography>
            </Box>
            </>
            :
            <>
            
            <Typography align="center" variant="h3">
            {(selectedDecade !== "" && selectedDecade < 1980) ||
            platforms.includes("VR") ? (
                <>
                <FormControl sx={{ m: 1 ,minWidth:'95px' }} disabled>
                  
                   <InputLabel
                      id="genres-label"
                      sx={{...inputSx,textAlign: "start",width:'110px'}}
                      shrink={false}
                      
                      >Genres</InputLabel>
                  <Select
                    
                    MenuProps={MenuProps}
                    sx={selectSx}
                  >
              
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl sx={{ m: 1, minWidth: "95px" }}>
                  <InputLabel id="genres-label" shrink={false} sx={inputSx}>Genres</InputLabel>
                  <Select
                    labelId="genres-label"
                    id="genres-label"
                    multiple
                    value={genres}
                    onChange={handleGenreChange}
                    input={<OutlinedInput label="Genres" />}
                    renderValue={() => null}
                    MenuProps={MenuProps}
                    sx={selectSx}
                  >
                    {Object.values(genresList).map((genre) => (
                      <MenuItem key={genre.genreId} value={genre.genreName}>
                        <Checkbox
                          checked={genres.indexOf(genre.genreName) > -1}
                        />
                        <ListItemText
                          primary={genre.genreName}
                          sx={{ textAlign: "start" }}
                        ></ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Typography>
            {/* } */}
            
            <Typography align="center" variant="h3">
              {selectedDecade !== "" && selectedDecade < 1990 ? (
                <>
                  <FormControl sx={{ m: 1 ,minWidth:'85px' }} disabled>
                    {/* <InputLabel id="platforms-label" sx={{ ...inputSx, pointerEvents: 'none', whiteSpace: 'nowrap', overflow: 'none',
                     textOverflow: 'none',maxWidth:'none'  }} shrink={false}  >Retro</InputLabel> */}
                     <InputLabel
                        id="platforms-label"
                        sx={{...inputSx,textAlign: "start",width:'110px'}}
                        shrink={false}
                        
                        >Retro</InputLabel>
                    <Select
                      
                      MenuProps={MenuProps}
                      sx={selectSx}
                    >
                
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl sx={{ m: 1, minWidth: "115px",
                            "&.MuiFormLabel-root-MuiInputLabel-root.Mui-focused":{
                                color: 'black'
                            },}} variant="outlined" style={{ border: 'none' }}>
                    <InputLabel
                        id="platforms-label"
                        sx={{...inputSx,textAlign: "start"}}
                        shrink={false}
                        
                        >Platforms</InputLabel>
                    <Select
                        labelId="platforms-label"
                        id="platforms-label"
                        multiple
                        value={platforms}
                        onChange={handlePlatformChange}
                        input={<OutlinedInput label="Platforms" />}
                        displayEmpty={true}
                        renderValue={() => null}
                        MenuProps={MenuProps}
                        sx={selectSx}
                    >
                      {Object.keys(platformList).map((platform) => (
                        <MenuItem key={platform} value={platform} sx={{minWidth:'115px', width: '100%'}}>
                          <ListItemText
                            primary={platform}
                            sx={{ textAlign: "start" }}
                          ></ListItemText>
                          <Checkbox
                          
                            checked={platforms.indexOf(platform) > -1}
                          />
                        </MenuItem>
                      ))}
                      
                    </Select>
                  </FormControl>
                </>
              )}
            </Typography>

            <Typography align="center" variant="h3">
              <FormControl sx={{ m: 1, minWidth: "80px" }}>
                {selectedDecade?<></>:   
                <InputLabel  id="date" sx={{...inputSx,textAlign: "start"}} shrink={false} >Date</InputLabel>
                }
                <Select
                  labelId="date"
                  id="date"
                  value={selectedDecade}
                  label="Date"
                  onChange={handleDecadeChange}
                  sx={selectSx}
                  renderValue={()=>`${selectedDecade}s`}
                >
                  {decadeListFiltered.map((decade) => (
                    <MenuItem value={decade}>
                      <ListItemText
                        primary={`The ${decade}s`}
                        sx={{ textAlign: "start" }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Typography>
           
            {
              (genres.length>0 || selectedDecade || platforms.length>0 ) &&
              <>
            <Box sx={{display:{xs:'none',sm:'flex'}}} >
            
            <StyledButton  text="Clear Filters" sx={{...ButtonSx,fontSize:{xs:'0.5rem',sm:'0.7rem'},padding:'3px 15px'}} onClick={()=>handleClearFilters()}>
            
            </StyledButton>
            </Box>
            <Box sx={{display:{xs:'flex',sm:'none'}}} >
            <HighlightOffIcon onClick={()=>handleClearFilters()}/>
            </Box>
              </>
            }
            </>}
            </Box>

            {/* Ranked Cards */}
          <Box   sx={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignSelf:'flex-start',alignItems:{xs:' ',md:'stretch'},width: "100%",height:'100%',minHeight:{xs:'301px',sm:'385px'},borderBottom: "1px Solid grey"}}>  
          {isLoading?
          <>
            <Box sx={{display:'flex',justifyContent:'center',alignSelf:'center'}} >
            <CircularProgress />
            </Box>
          </>
          :
          <>
            {isLeftLoading?
              <>
              <Box sx={{...gameCardSx,marginY:'auto'}} >
                <CircularProgress  />
              </Box>
              </>
            :
            <>
            <Box  sx={gameCoverSx} > 
            {/* <Box sx={{display:'flex',flexDirection:{xs:'column-reverse',sm:'column-reverse'}}}> */}
            <Box>
              <InfoOutlinedIcon sx={infoSx} onClick={()=>handleLeftInfoIconClick()}/>  
            </Box>
              <GameCard m={2} game={leftCard} cardProps={{ onClick: () => handleLeftCardClick() }} cardSx={{width:{md:'224px'},height:{md:'305px'},}} boxSx={{justifyContent:{xs:'flex-start',sm:'center'}}} />   
              {/* </Box> */}
                
            </Box>
            </>
          }
            
            <Divider orientation="vertical" flexItem sx={{width:'2px',background: "linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.0) 95%)" }}/>
          
            {isRightLoading?
            <>
            <Box sx={{...gameCardSx,marginY:'auto'}} >
            <CircularProgress />
            </Box>
            </>
            :
            <>
            <Box  sx={{...gameCoverSx,alignItems:{xs:'flex-end',sm:''},"&:hover .gameBox": {
              background: "linear-gradient(140deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.0) 60%)", 
              transition: "background-color 0.3s ease-in-out",
              transform:'scale3d(1.02,1.02,1.02)',
              transition: "transform 0.1s ease-in",
            }}} > 
                 {/* <Box sx={{display:'flex',alignItems:'flex-end',flexDirection:{xs:'column-reverse',sm:'column-reverse'}}}> */}
                 <Box>
                    <InfoOutlinedIcon sx={{...infoSx,right:'0px'}} onClick={()=>handleRightInfoIconClick()}/>  
                 </Box>
                    <GameCard  game={rightCard} cardProps={{ onClick: () => handleRightCardClick() }} cardSx={{width:{md:'224px'},height:{md:'305px'}}} boxSx={{justifyContent:{xs:'flex-start',sm:'center'}}} />       
                  {/* </Box>   */}
            </Box>
            </>
          }
          </>}
                  
          </Box>
          <Box sx={{width:'100%',display:'flex',justifyContent:'space-evenly',alignItems:'center'}}>
                    <StyledButton  sx={ButtonSx}  text="Haven't played" fontWeight='400' disabled={isReranking} onClick={()=>handleNotPlayed(leftCard)}/>
                    <StyledButton  sx={ButtonSx}   text="Haven't played either" fontWeight='400' disabled={isReranking} onClick={()=>handleNotPlayed()}/>
                    <StyledButton  sx={ButtonSx}  text="Haven't played" fontWeight='400' disabled={isReranking} onClick={()=>handleNotPlayed(rightCard)} />
          </Box>
        </Box>

        </>
    );
};

export default Rank;