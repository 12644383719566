import { createContext, useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

// import jwt from 'jsonwebtoken';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [cookies, setCookie,removeCookie] = useCookies(['token']);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8080'
  const [ userName, setUserName ] = useState(null)
  // Function to generate JWT token
  // const generateToken = (user) => {
  //   const token = jwt.sign({ user }, 'SECRET_KEY', { expiresIn: '1d' }); // Replace 'SECRET_KEY' with your secret key
  //   return token;
  // };

  // Function to verify JWT token
  const verifyToken = async (token) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/users/login-with-cookie`,
        {
          token: token,
        }
      );
      
      if (response && response.status === 200 && response.data) {
        console.log(response)
        setUserName(response.data.user.userName)
        return true

      } else {
        return false
      }
    } catch (error) {
      console.error("Failed to log in with cookie:", error);
    }
  };
  

  useEffect(() => {
    console.log('auth context running')
    // Check if token exists in cookies
    const tokenFromCookie = cookies.token;
    if (tokenFromCookie) {
      const user = verifyToken(tokenFromCookie);
      if (user){
        setToken(tokenFromCookie);
        localStorage.setItem('token', tokenFromCookie);
      }
    } else {
      // Check if token exists in localStorage
      const tokenFromLocalStorage = localStorage.getItem('token');
      if (tokenFromLocalStorage) {
        const user = verifyToken(tokenFromLocalStorage);
        if (user) {
          setToken(tokenFromLocalStorage);
          setCookie('token', tokenFromLocalStorage, { path: '/', maxAge: 344000 }); // Set token in cookie with max age of 1 day
        } else {
          localStorage.removeItem('token');
        }
      }
    }
  }, []);

  // Function to handle login and set token
  // const handleLogin = (user) => {
  //   const token = generateToken(user);
  //   setToken(token);
  //   setCookie('token', token, { path: '/', maxAge: 86400 }); // Set token in cookie with max age of 1 day
  //   localStorage.setItem('token', token);
  // };

  // Function to handle logout and remove token
   const handleLogout = () => {
    setToken(null);
    setCookie('token', null, { path: '/', maxAge: -1 });
    removeCookie('token');
    localStorage.removeItem('token');
    setUserName(null)
  };

  return (
    <AuthContext.Provider value={{ token,setToken,setUserName, userName, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};