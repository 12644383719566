import { React, useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { isMobileView } from "../Helpers/isMobile";
import Leaderboards from "../Components/Leaderboards";
import { TypographyStyles } from "../GlobalStyles/TypographyStyles";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import CircularProgress from '../GlobalStyles/Logo';
import { notifyError } from "../Helpers/Toasts";
import { ToastContainer } from "react-toastify";
import ChartsCarousel from "../Components/ChartsCarousel";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { UserStats } from "../Hooks/UserStats";
import GamePreviewCarousel from "../Components/GamePreviewCarousel";
import Donate from "../Components/Donate";

const Charts = () => {
const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080";
const [isLoading,setIsLoading] = useState(true)
  const [isMobile, setIsMobile] = useState();
  const {topUsers,topMonthlyUsers,topWeeklyUsers} = UserStats();
  const[backgroundUrl,setBackgroundUrl]=useState()
  useEffect(() => {
    if (!isMobile) {
      setIsMobile(isMobileView());
    }
    
    if (!charts){
      setIsLoading(true)
      refetch()
    }
    else{
      setIsLoading(false)
    }
  }, []);
  const [userStatsType,setUserStatsType] = useState("allTime")
  const handleUserStatsChange = (event, newValue) => {
    setUserStatsType(newValue);
  };
  
  const chartTitleSx= {...TypographyStyles.heading4,fontWeight:'600'}
  const {data : charts,refetch} = useQuery ('Charts',async ()=>{
    const response = await axios.get(`${baseUrl}/api/charts/get-all-charts`)
    
    if (response && response.data ){
      return response.data.charts
    } 
  },{staleTime:Infinity,retry:3,onSuccess:()=>{
    setIsLoading(false)
  },})
  
  // Define your custom order of chart names
  const customOrder = ["mostBattlesWon","newReleases", "topGames","mostBattlesLost", "mostViewed", "highlyAnticipatedGames", "mostDislikedGames"];

// Sort the chart objects based on the custom order
  const sortedCharts = charts?.sort((a, b) => {
      const indexA = customOrder.indexOf(a.identifier);
      const indexB = customOrder.indexOf(b.identifier);
      
      return indexA - indexB;
  });
  if (isLoading){
    return(
      <>
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100vh'}}>
          <CircularProgress/>
        </Box>
      </>
    )
  }
  else{
    return (
      <>
        <Box sx={{position:'absolute',width:'100%',zIndex:-1,height:'100%',maxHeight: { md: 'calc(100vh - 64px)' },overflow:'hidden',}}>
        {
          backgroundUrl &&
            <img src={`https://images.igdb.com/igdb/image/upload/t_original/${backgroundUrl}.jpg`}
                  style={{ width: "100%",height:'100%',filter: 'blur(15px)',opacity:0.15 }}
            />
        }
        </Box>              
          <Box
          
            sx={{
              position:'relative',
              display: "flex",
              flexDirection: 'column',
              justifyContent: "space-between",
              alignItems: "flex-start",
              justifySelf: "center",
              marginTop: { xs: "10px", md: "10px" },
              maxWidth:{md:'1440px',xl:'1640px'},
              width:'100%',
              height: { md: 'calc(100vh - 200px)' },
              zIndex:2,
              
              mx:'auto',
            }}
          >
              <Typography sx={{...TypographyStyles.heading1,fontWeight:'700',width:'100%',textAlign:{xs:'center',md:'start'}}}>
                        Charts
              </Typography>
              <Box  sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", md: "row" },
                justifyContent: "space-between",
                alignItems: "flex-start",
                justifySelf: "center",
                height:"100%",
                width:'100%',
                // background: "rgba(255, 255, 255, 0.01)",
                // border: "0.8px solid rgba(255, 255, 255, 0.29)",
                // backdropFilter: "blur(10px)",
                
                borderRadius: "10px",
                mx:'auto',
               
              }}>
                <Box   sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height: '100%',mx:'auto'}}>
                  <Box mx={3} mt={1} sx={{width:'310px',
                    border: "0.8px solid rgba(255, 255, 255, 0.29)",borderRadius: "10px",maxHeight:'580px'}}>
                    <Typography mt={3} sx={{ ...TypographyStyles.heading4, fontWeight: "600",width:'100%',textAlign:'center' }}>
                      Top Rankers
                    </Typography>
                    <TabContext value={userStatsType}>
                      <Box sx={{ display:'flex',flexDirection:'column', borderColor: 'divider',justifyContent:'center',alignItems:'center' }}>
                        <TabList onChange={handleUserStatsChange} aria-label="lab API tabs example"  >
                          <Tab label="Global" aria-label="allTime" value="allTime" />
                          <Tab label="Monthly"  color='white' aria-label="monthly" value="monthly" />
                          <Tab label="Weekly"  aria-label="weekly" value="weekly"/>
                        </TabList>
                        <Box sx={{width:'100%'}}>      
                            <TabPanel value="allTime" sx={{padding:'10px'}}>
                                <Leaderboards  data={topUsers}/>
                              </TabPanel>
                              <TabPanel value="monthly" title="Top Users" sx={{padding:'10px'}}>
                                <Leaderboards data={topMonthlyUsers} value={"monthly"}/>
                              </TabPanel>
                              <TabPanel value="weekly" title="Top Users" sx={{padding:'10px'}}>
                                <Leaderboards data={topWeeklyUsers} value={"weekly"}/>
                              </TabPanel>
                        </Box>
                      </Box>
                    </TabContext>
                  </Box>
                  <Box sx={{height:'100%'}} mx={3} my={3}>
                    <Donate/>
                  </Box>
                </Box>
                <Box m={1} p={isMobile?0:2}  sx={{display:'flex',justifyContent:'flex-start',flexDirection:'column',mx:{xs:'auto',md:'0'},height:'100%',width:'100%',maxWidth:'1280px',overflowX:'hidden', background: "rgba(255, 255, 255, 0.01)",
                border: "0.8px solid rgba(255, 255, 255, 0.29)",borderRadius: "10px",}}>
                    {
                        charts &&
                          
                          <Box my={1} sx={{width:'100%'}}>
                            <Box  sx={{postion:'relative',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'flex-start'}}>
                              <Typography sx={{ ...TypographyStyles.heading4, fontWeight: "700",width:'100%',textAlign:{xs:'center',md:'start'} }}>
                                Game Of the Day
                              </Typography>
                              <GamePreviewCarousel setBackgroundUrl={setBackgroundUrl} game={charts.find((chart)=>chart.identifier==="gameOfTheDay").games[0]}/>
                            </Box>
                          
                            {
                              sortedCharts.filter((chart)=>chart.identifier!=="gameOfTheDay").map((chart)=>
                                <>
                                  <ChartsCarousel title={chart.name} games={chart.games}/>
                                </>
                              )
                            }
                          </Box>
                    }
                </Box>
              </Box>
          </Box>
                
      </>
    );
  }
};

export default Charts;
