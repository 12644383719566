import {React,useState,useEffect,useContext} from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import StyledTextField from '../GlobalStyles/StyledTextField';
import StyledButton from '../GlobalStyles/StyledButton';
import { useQuery,useMutation } from 'react-query';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { ToastContainer } from 'react-toastify';
import  { notifyError, notifySuccess } from "../Helpers/Toasts"
import 'react-toastify/dist/ReactToastify.css';
// import { UserContext } from '../Contexts/UserContext';
import { AuthContext } from '../Contexts/AuthContext';
const SignUp = ({setLander}) => { 
  const navigate = useNavigate();
  
  const { userName,setUserName } = useContext(AuthContext); 
  const { token,setToken } = useContext(AuthContext);
  const baseUrl= process.env.REACT_APP_BASE_URL 
  // || 'http://localhost:8080'
  const [formData, setFormData] = useState({
    userName:'',
    userEmail: '',
    userPassword: '',
  });
  const [cookies, setCookie] = useCookies(['token']);
  const signUpMutation = useMutation(
    () => axios.post(`${baseUrl}/api/users/register`, formData),
    {
      onSuccess: (data) => {
        console.log(data)
        setCookie('token', data?.data.token, { maxAge: 604800 }); // Set the cookie with an expiration time of 7 day (604800 seconds)
        setToken(data?.data.token);
        localStorage.setItem('token', data?.data.token);
        setUserName(data?.data.user.userName)
        console.log(data?.data?.msg)
        notifySuccess(data?.data?.msg)
        navigate('/ranking')
      },
      onError: (error) => {
        notifyError(error.response.data.msg)
        console.log(error.response.status)
      },
    }
  );
    const handleSubmit = (event) => {
    event.preventDefault();
    
    signUpMutation.mutate({ userName:formData.userName,userEmail:formData.email,userPassword: formData.password });
  };
      const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
 
    return (
        <>
            <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Start ranking now
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} >
            <Grid container spacing={2} >
              <Grid item xs={12} >
              <StyledTextField
                autoComplete="given-name"
                name="userName"
                required
                fullWidth
                id="userName"
                label="User Name"
                value={formData.userName}
                onChange={handleInputChange}
                autoFocus
                label-color="rgba(255, 255, 255, 0.9)"
                />

              </Grid>
              
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email Address"
                  name="userEmail"
                  autoComplete="email"
                  value={formData.userEmail}
                  onChange={handleInputChange}
                  labelcolor="rgba(255, 255, 255, 0.9)"
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  name="userPassword"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.userPassword}
                  onChange={handleInputChange}
                labelcolor="rgba(255, 255, 255, 0.9)"
                />
              </Grid>
            </Grid>
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              text="Sign Up"
              
              sx={{ mt: 3, mb: 5,backgroundColor:"#3656FF" }}
            />
            
            <Grid container justifyContent="flex-end">
              <Grid item>
              <Link
                sx={{
                    color: '#3656FF',
                    cursor: 'pointer',
                    textDecorationColor: 'transparent',
                    '&:hover': {
                        color: '#3656FF',
                        textDecorationColor: '#3656FF',
                    },
                }}
                variant="body2"
                onClick={()=>setLander("login")}
                >
                Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      <ToastContainer />
      </Container>
        </>
    );
};

export default SignUp;