import {React,useState,useEffect,useContext,useRef} from 'react';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

const Discussions = () => {
    return (
        <>
        <Box sx={{ display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',justifySelf:'center',marginTop:{xs:'10px',md:'40px'}}}>
                    <Typography variant="h3" component="span" sx={{ flexGrow: 1,fontFamily: "'Inter', sans-serif",fontWeight:'500',padding:'10px',textAlign:"center",fontSize: { xs:'2.0rem',sm: '2.4rem', md: '2.5rem' }, }}>
                        Coming Soon
                    </Typography>
                    <Box m={3} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',filter: 'drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.33))'}}>
                        <img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1685180864/Screenshot_2023-05-27_151702_zirzwz.png" style={{width:'100%', maxWidth:'874px',borderRadius:'20px'}}/>
                    </Box>
        </Box>
        </>
    );
};

export default Discussions;