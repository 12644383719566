import { React, useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "@mui/material";
import { Box, Container, Typography } from "@mui/material";
import { TypographyStyles } from "../GlobalStyles/TypographyStyles";
import GameCardSmall from "./GameCardSmall";
import GameCard from "./GameCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { GameInfoHook } from "../Hooks/GameInfoHook";
import MediaCarousel from "./MediaCarousel";
import ReactPlayer from "react-player/lazy";
import CircularProgress from '../GlobalStyles/Logo';
import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import { CardActionArea } from "@mui/material";
// import ReactPlayer from 'react-player'
// import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
const GamePreviewCarousel = ({ game,...rest }) => {
  const splideRef = useRef(null);
  const navigate = useNavigate()
  // const playerRef = useRef();
  const {setBackgroundUrl} = rest
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    companies,
    genres,
    platforms,
    media,
    similarGames,
    position,
    commentCount,
  } = GameInfoHook({
    gameObjectId: game._id,
  });
  useEffect(()=>{
    const randomIndex = Math.floor(Math.random() * media?.screenshots.length);
    setBackgroundUrl(media?.screenshots[randomIndex]?.imageId)
  },[media])
  const [restrictedVideoArray,setRestrictedVideoArray] = useState([])
  const [activeSlide, setActiveSlide] = useState(0);
  const [seekTime,setSeekTime] = useState()
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoProgress, setVideoProgress] = useState([]);
  const playerRefs = useRef([]);
  const splideOptions = {
    perPage: 1,
    speed: 400,
    arrows: isXsScreen ? false : false,
    pagination: true,
    // autoplay:true,
    loop:true,
    rewind:true,
    interval:20000
  };
  const handleMove = (splide, newIndex) => {
    
    setActiveSlide(newIndex);
    // playerRef.current.seekTo(seekTime, 'seconds');
  };
  const handlePlay = (videoIndex) => {
    setIsPlaying(true)
    setVideoProgress((prevProgress) => {
      const updatedProgress = [...prevProgress];
      updatedProgress[videoIndex] = 0;
      return updatedProgress;
    });
    playerRefs.current.forEach((playerRef, index) => {
      if (index === videoIndex) {
       
        playerRef.seekTo(0.25,'fraction'); 
      } 
    });
  };
  
  const handleOnError = (video) => {
    console.log(video)
    setRestrictedVideoArray(prevArray => [...prevArray, video.videoId]);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          zIndex: 2,
          display:'flex',
          flexDirection:{xs:'column',lg:'row'},
          
        }}
      >
        <Box
        onClick={()=>window.open(`/game/${game?.slugName}`)}
          sx={{
            
            position: "relative",
            width: "100%",
            zIndex: 1,
            maxWidth: {xs:'100%',lg:"922px"},height:'100%',
            
          }}
        >
          <Splide options={splideOptions} ref={splideRef} onMove={handleMove}>
            { media?.videos
            ?.filter(video => !restrictedVideoArray.includes(video.videoId))
            .map((video,index) => (
              !restrictedVideoArray.includes(index) && (
              <>
                <SplideSlide>
                  <Box key={video.videoId} m={isXsScreen ? 0.5 : 2} >
                    <Card sx={{ width: "100%", borderRadius: "8px" }}>
                      <CardActionArea>
                        <CardMedia>
                          <Box>
                            <Box
                              sx={{
                                position: "absolute",
                                boxShadow:
                                  "inset 20px -30px 65px 30px rgba(0, 0, 0, 0.6)",
                                zIndex: 3,
                                width: "100%",
                                height: "100%",
                              }}
                            >  
                            </Box>
                            <Box sx={{ position: "relative", zIndex: 2, width:'100%'}}>
                              <ReactPlayer
                                ref={(ref) => (playerRefs.current[index] = ref)}
                                url={`https://www.youtube.com/embed/${video?.videoUrl}`}
                                controls={false}
                                width={"100%"}
                                height={isXsScreen?'205px':"500px"}
                                style={{ userSelect: "none",position:'relative' }}
                                muted={true}
                                loop={true}
                                playsinline={true}
                                fallback={
                                  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
                                    <CircularProgress/>
                                  </Box>
                              }
                                playing={index === activeSlide || (index !== activeSlide && videoProgress[index] < 5)}
                                onProgress={({ playedSeconds }) => {
                                  setVideoProgress((prevProgress) => {
                                    const updatedProgress = [...prevProgress];
                                    updatedProgress[index] = playedSeconds;
                                    return updatedProgress;
                                  });
                                
                                }}
                                // onStart={() => handleStartVideo()}
                                onReady={() => {
                                  handlePlay(index)
                                }}

                              
                                
                                onError={() => handleOnError(video)}
                              />
                            </Box>
                          </Box>
                        </CardMedia>

                      </CardActionArea>
                    </Card>
                  </Box>
                </SplideSlide>
              </>
              )
            ))}
            {media?.screenshots
              ?.filter((screenshot) => screenshot.imageId) // Filter out screenshots without imageId
              .map((screenshot) => (
                <SplideSlide key={screenshot.imageId}> {/* Added key */}
                  <Box m={isXsScreen ? 0.5 : 2}>
                    <Card sx={{ width: "100%", borderRadius: "8px", display: { xs: 'flex', md: 'block' }, alignItems: 'center' }}>
                      <CardActionArea>
                        <CardMedia>
                          <Box>
                            <Box
                              sx={{
                                position: "absolute",
                                boxShadow:
                                  "inset 20px -30px 65px 30px rgba(0, 0, 0, 0.6)",
                                zIndex: 3,
                                width: "100%",
                                height: "100%",
                              }}
                            ></Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: "relative", zIndex: 2, height: { sm: '500px' }, backgroundColor: 'rgba(0,0,0,1)' }}>
                              <img
                                src={`https://images.igdb.com/igdb/image/upload/t_original/${screenshot.imageId}.jpg`}
                                style={{ width: "100%", height: '100%' }}
                                alt={`Screenshot ${screenshot.imageId}`}
                              />
                            </Box>
                          </Box>
                        </CardMedia>
                      </CardActionArea>
                    </Card>
                  </Box>
                </SplideSlide>
              ))
              .splice(0, 4)}
          </Splide>

          
          {
            media && 
            <>
              <Box
                m={4}
                sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 2 }}
              >
                <Typography
                  sx={{
                    ...TypographyStyles.heading3,
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  {game.gameName}
                </Typography>
                <Typography
                  sx={{
                    ...TypographyStyles.body1,
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  {genres}
                </Typography>
                {!isXsScreen && 
                  <Typography
                  sx={{
                    ...TypographyStyles.body2,
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  {platforms}
                </Typography>
                }
              </Box>
            </>
          }
        </Box>
        {
          !isXsScreen && 
          <>
            <Box sx={{width:'100%',maxWidth:{md:'100%',lg:'300px'},maxHeight:'500px',overflowY:'scroll',my:'auto'}}>
                  <Card sx={{background:'rgba(0, 0, 0, 0.4)'}}>
                    <Typography
                    mt={1.5}
                    sx={{
                      ...TypographyStyles.body1,
                      fontWeight: "600",
                      textAlign: "center",
                      
                    }}
                    >
                      Screenshots
                    </Typography>
                
                  <Box sx={{display:'flex',flexDirection:{xs:'row',lg:'column'},alignItems:'center',overflow:'hidden'}}  m={isXsScreen ? 0.5 : 1.5}>
                      {media?.screenshots
                    ?.filter((screenshot) => screenshot.imageId) // Filter out screenshots without imageId
                    ?.map((screenshot) => (
                        <Box m={1}>
                          <Card sx={{ minWidth: {xs:'230px',sm:"100%"} }}>
                            <CardActionArea>
                              
                                  <Box onClick={()=>window.open(`https://images.igdb.com/igdb/image/upload/t_original/${screenshot.imageId}.jpg`, 'rel=noopener noreferrer')}>
                                    <img
                                      src={`https://images.igdb.com/igdb/image/upload/t_original/${screenshot.imageId}.jpg`}
                                      style={{ width: "100%",height:'150px' }}
                                      />
                                  </Box>
                            
                              {/* <CardContent>
                                      <Typography variant="body2" color="text.secondary">
                                      Trailer
                                      </Typography>
                                    </CardContent> */}
                            </CardActionArea>
                          </Card>
                        </Box>
                    
                    ))
                    }
                  </Box>
                  
                    
                    
                  </Card>
            </Box>
          </>
        }
      </Box>
      <style jsx={true}>{`

.splide__arrow {
  background:rgba(255, 255, 255, 0.29);
  
    align-items: center;
    
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2em;
    justify-content: center;
    opacity: .9;
    margin-left: -20px;
    margin-right: -20px;
    position: absolute;
    top: 40%;
    transform: translateY(0%); 
    z-index: 1;
}

.splide__arrow:hover {
  background:rgba(255, 255, 255, 0.49);
  
}

`}</style>
    </>
  );
};

export default GamePreviewCarousel;
