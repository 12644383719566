import React, { useEffect, useState } from 'react';
import { Box,Container,Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useQuery } from "react-query";
import moment from "moment";
import axios from "axios";
const InfoBox = ({game,boxSx}) => {
    const [isLoading,setIsLoading] = useState(true)
    const[companies,setCompanies]=useState(null)
    const winPercent = (game?.timesWon/game?.timesRanked)*100;
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080";
    const {data : platforms ,isLoading : platformLoading,error: platformError} = useQuery (['platform',game?._id],async ()=>{
      const response = await axios.post(`${baseUrl}/api/game/get-game-platforms`,{gameObjectId:game?._id})
      if (response && response.data && response.data.platforms){
        return response.data.platforms.slice(0,4).map(platform => platform.platformName).join(', ');
      } 
    },{staleTime:Infinity})
    const { data: companyQuery, isLoading: companyLoading, error: companyError, refetch } = useQuery(
      ['company', game._id],
      async () => {
        const response = await axios.post(`${baseUrl}/api/game/get-game-companies`, { gameObjectId: game._id });
        if (response && response.data && response.data.company) {
          return response.data.company[0]?.companyName;
        }
      },
      {
        staleTime: Infinity,
        enabled: true,
        // Set staleTime to Infinity
        // Other options...
        onSuccess: (data) => {
          setCompanies(data);
          setIsLoading(false);
        }
      }
    );
      useEffect(()=>{
        setIsLoading(true)
        refetch();
      },[game])
    return (
        <>

            <Box m={1}  sx={{display:'flex',justifyContent:{sm:'flex-start',md:'center'},width:'100%',alignItems:'center',...boxSx}}>
                <Box p={2} sx={{display:'flex',alignItems:'center',}}>
                <Avatar sx={{width:{xs:'100px',sm:'71px'},height:{xs:'100px',sm:'71px'}}} alt={game?.gameName} src={`https://images.igdb.com/igdb/image/upload/t_cover_small/${
                      game.coverUrl
                    }.png`}/>
                </Box>
                <Box mx={2} m={0.5} sx={{display:'flex',flexDirection:'column',justifyContent:'flex-start',maxWidth:'330px'}} >
                  {companies && 
                    <Typography   component="div" sx={{ fontFamily: "'Inter', sans-serif"
                        ,fontWeight:'300',textAlign:"start",
                        fontSize: { xs:'0.8rem',sm: '1.0rem', md: '0.8rem',xl:'1.0rem' }, }}>
                        <b>Game Studios</b> | {companies?.split(' ').slice(0,3).join(' ')}
                    </Typography>
                  }

                    <Typography  component="div" sx={{ flexGrow: 0,fontFamily: "'Inter', sans-serif"
                        ,fontWeight:'300',textAlign:"start",
                        fontSize: { xs:'0.8rem',sm: '1.0rem', md: '0.8rem',xl:'1.0rem' },
                        wordBreak: 'break-word',}}>
                            <b>Released</b> | {game?.firstReleaseDate? 
                     moment.unix(game.firstReleaseDate).format('LL')
                  :"-"}
                            
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        flexGrow: 0,
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '300',
                        textAlign: 'start',
                        fontSize: { xs: '0.8rem', sm: '1.0rem', md: '0.8rem', xl: '1.0rem' },
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: {xs:'190px',sm:'100%'},
                      }}
                    >
                      <b>Platforms</b> | {platforms}
                    </Typography>
                    <Typography  component="div" sx={{ flexGrow: 0,fontFamily: "'Inter', sans-serif"
                        ,fontWeight:'300',textAlign:"start",
                        fontSize: { xs:'0.8rem',sm: '1.0rem', md: '0.8rem',xl:'1.0rem' },
                        wordBreak: 'break-word',}}>
                            <b>Total times ranked</b> | {game.timesRanked?game.timesRanked: 0}
                            
                    </Typography>
                    <Typography  component="div" sx={{ flexGrow: 0,fontFamily: "'Inter', sans-serif"
                        ,fontWeight:'300',textAlign:"start",
                        fontSize: { xs:'0.8rem',sm: '1.0rem', md: '0.8rem',xl:'1.0rem' },
                        wordBreak: 'break-word',}}>
                            <b>Win Percent</b> | {winPercent?winPercent.toFixed(2):0}%  
                    </Typography>
                    <Typography  component="div" sx={{ flexGrow: 0,fontFamily: "'Inter', sans-serif"
                        ,fontWeight:'300',textAlign:"start",
                        fontSize: { xs:'0.8rem',sm: '1.0rem', md: '0.8rem',xl:'1.0rem' },
                        wordBreak: 'break-word',}}>
                            <b>Rating</b> | {game?.totalRating?.toFixed(2)} 
                    </Typography>
            </Box>

            </Box>
           
        </>
    );
};

export default InfoBox;