import React from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({ labelColor, ...rest }) => {
  const [focused, setFocused] = React.useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const styledInputLabelProps = {
    style: {
      color: focused ? labelColor : 'rgba(255, 255, 255, 0.5)',
    },
    position: 'above',
    required: true,
  };

  const styledInputSx = {
    '& .MuiInputBase-input': {
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '100px',
      borderColor: 'rgba(255, 255, 255, 0.5)',
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
  };

  return (
    <TextField
      {...rest}
      InputLabelProps={styledInputLabelProps}
      sx={styledInputSx}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default CustomTextField;
