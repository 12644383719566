import { Box, CircularProgress, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { TypographyStyles } from '../GlobalStyles/TypographyStyles';
const GameCard = ({ game, ...rest }) => {
  const { boxSx,cardProps, titleSx, cardSx, imgSx,charts,index,showWinPercent } = rest;
  const navigate = useNavigate();
  const winPercent = (game?.timesWon / game?.timesRanked) * 100;
  const [isLoaded, setIsLoaded] = useState(false);
  const [lineBreak,setLineBreak] = useState(false)
  const handleNameClick = () => {
    window.open(`/game/${game?.slugName}`);
  };
  useEffect(()=>{
    if(game?.gameName?.split(' ').length>=9){
      // console.log('game Name too long')
      setLineBreak(true)
    }
  },[])

  const fadeAnimation = {
    opacity: 0,
    transform: 'translateY(10px)',
    animation: 'fade-in-animation 0.5s ease-in-out',
    animationFillMode: 'forwards',
  };

  const styles = `
    @keyframes fade-in-animation {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <Box
        className="gameBox"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          ...boxSx
        }}
        >
        <Box
          m={1}
          sx={{
            display: 'flex',
            maxWidth: '244px',
            justifyContent: 'center',
            cursor: 'pointer',
            ...cardSx,
          }}
          {...cardProps}
          >
          {1 && (
            <Box
            sx={{
              position:'relative',
              ...fadeAnimation,
              
            }}
            >
              {charts?
              <>
                <Typography sx={{ ...TypographyStyles.body1, fontWeight: "600",textAlign:{xs:'center',md:'start'},width:'100%',color:'rgba(255,255,255,0.6)' }}>
                      #{index+1}   
                  </Typography>
              <Card onClick={handleNameClick}>
                <CardActionArea>
                  <img
                    src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${game?.coverUrl}.png`}
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: '325px',
                      maxWidth: '244px',
                      filter: 'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.25)) ',
                      borderRadius: '10px',
                    
                      ...imgSx,
                    }}
                    onLoad={() => setIsLoaded(true)}
                  />
            </CardActionArea>
          </Card>
              </>
              
              :
              <img
                    src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${game?.coverUrl}.png`}
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: '325px',
                      maxWidth: '244px',
                      filter: 'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.25)) ',
                      borderRadius: '10px',
                    
                      ...imgSx,
                    }}
                    onLoad={() => setIsLoaded(true)}
                  />
              
              }
            </Box>
          )}
        </Box>
        <Typography
          marginY={1}
          marginX={1}
          onClick={handleNameClick}
          variant="h3"
          component="div"
          sx={{
            fontFamily: "'Inter', sans-serif",
            fontWeight: '600',
            textAlign: 'center',
            fontSize: lineBreak?{xs:'1.0rem',sm: '1.0rem', md: '0.80rem'}:{ xs: '1.0rem', sm: '1.0rem', md: '1.1rem' },
            '&:hover': { textDecoration: 'underline' },
            cursor: 'pointer',
            whiteSpace: 'break-spaces',
            ...titleSx,
            
          }}
        >
          {game?.gameName ? game.gameName : ''}
            {charts && !!winPercent && showWinPercent &&
              <> 
              <Typography
                px={1}
                component="span"
                sx={{
                  ...TypographyStyles.body2, // Assuming this contains your default styles
                  fontWeight: "600",
                  textAlign: { xs: 'center', md: 'center' },
                  width: '100%',
                  color: '#3656FF',
                  ...(lineBreak ? TypographyStyles.caption : {}),
                }}
              >
                  ({winPercent.toFixed(0)}%)
                </Typography>
              </>
            }
        </Typography>
      </Box>
    </>
  );
};

export default GameCard;


